import React from "react";

const Home = ({ fill, opacity }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.997"
      height="31.989"
      viewBox="0 0 32.997 31.989"
      style={{
        marginBottom: 7,
      }}
    >
      <g
        id="그룹_8449"
        data-name="그룹 8449"
        transform="translate(-43.501 -116.608)"
        opacity={opacity}
      >
        <g id="그룹_8448" data-name="그룹 8448">
          <path
            id="패스_27129"
            data-name="패스 27129"
            d="M63.987,136.847v.7q0,4.535,0,9.069c0,1.4.547,1.965,1.931,1.968q2.661.008,5.324,0c1.4,0,2-.61,2-1.99q0-6.582,0-13.165v-.61a3.621,3.621,0,0,0,1.15.552c.92.059,2.318-1.547,2.077-2.423a3.023,3.023,0,0,0-.921-1.352q-7.04-6.21-14.122-12.372a1.874,1.874,0,0,0-2.808.03q-7.225,6.618-14.446,13.241a1.589,1.589,0,0,0-.123,2.5c.9.989,1.774,1.078,2.686.276.263-.231.533-.456.884-.756v.848q0,6.61,0,13.223c0,1.31.6,1.979,1.837,1.994q2.691.03,5.382,0a1.631,1.631,0,0,0,1.787-1.832q0-4.623,0-9.245v-.657Z"
            fill={fill}
          />
          <path
            id="패스_27130"
            data-name="패스 27130"
            d="M63.987,136.847H56.629v.657q0,4.623,0,9.245a1.631,1.631,0,0,1-1.787,1.832q-2.691.032-5.382,0c-1.233-.015-1.836-.684-1.837-1.994q-.006-6.612,0-13.223v-.848c-.351.3-.621.525-.884.756-.912.8-1.784.713-2.686-.276a1.589,1.589,0,0,1,.123-2.5q7.221-6.622,14.446-13.241a1.874,1.874,0,0,1,2.808-.03Q68.5,123.4,75.55,129.6a3.023,3.023,0,0,1,.921,1.352c.241.876-1.157,2.482-2.077,2.423a3.621,3.621,0,0,1-1.15-.552v.61q0,6.582,0,13.165c0,1.38-.606,1.986-2,1.99q-2.662.009-5.324,0c-1.384,0-1.93-.563-1.931-1.968q0-4.534,0-9.069Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default Home;
