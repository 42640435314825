function extractSingleRegionData(allData) {
  const topLevelAddressCount = {};

  allData.forEach((data) => {
    const targetAddress = data.addressData.split(" ");
    let detectedTopLevelAddress = null;

    // targetAddress = [changwon, yongho, ildong]
    for (const topLevelAddress of targetAddress) {
      if (
        topLevelAddress.endsWith("시") ||
        topLevelAddress.endsWith("군") ||
        topLevelAddress.endsWith("구")
      ) {
        detectedTopLevelAddress = topLevelAddress;
        break;
      }
    }

    if (detectedTopLevelAddress) {
      if (!topLevelAddressCount[detectedTopLevelAddress]) {
        topLevelAddressCount[detectedTopLevelAddress] = {
          total: 0,
          status: {},
        };
      }
      topLevelAddressCount[detectedTopLevelAddress].total += 1;

      const status = data.status;
      if (topLevelAddressCount[detectedTopLevelAddress].status[status]) {
        topLevelAddressCount[detectedTopLevelAddress].status[status] += 1;
      } else {
        topLevelAddressCount[detectedTopLevelAddress].status[status] = 1;
      }
    }
  });

  // const totalCounting = topLevelAddresses.reduce((count, address) => {
  //   count[address] = (count[address] || 0) + 1;
  //   return count;
  // }, {});
  //
  // // for check number of Equipment list to detect devices
  // const detectedDevicesNumber = Object.values(totalCounting).reduce(
  //   (acc, cur) => acc + cur,
  //   0,
  // );

  return topLevelAddressCount;
}

export default extractSingleRegionData;
