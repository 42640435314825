import React from "react";

const History = ({ fill, opacity }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.635"
      height="33.707"
      viewBox="0 0 33.635 33.707"
      style={{
        marginBottom: 7,
      }}
    >
      <g
        id="그룹_8438"
        data-name="그룹 8438"
        transform="translate(-43.182 -212.52)"
        opacity={opacity}
      >
        <path
          id="패스_27116"
          data-name="패스 27116"
          d="M59.995,246.227c-.512-.037-.99-.061-1.466-.107-1.117-.108-1.25-.237-1.324-1.33a3.693,3.693,0,0,0-1.531-2.906,3.743,3.743,0,0,0-4.73.289c-.761.679-1.1.711-1.837.03s-1.428-1.389-2.1-2.124c-.64-.7-.61-1.036.023-1.752a3.716,3.716,0,0,0-.156-5.3,3.463,3.463,0,0,0-2.253-.967c-1.036-.053-1.374-.332-1.419-1.349-.048-1.071-.012-2.149.029-3.222a.9.9,0,0,1,.948-.94,4.232,4.232,0,0,0,3.052-1.292,3.7,3.7,0,0,0-.054-4.955c-.707-.778-.743-1.124-.043-1.88s1.422-1.451,2.173-2.134c.643-.586,1.058-.549,1.71.046a3.7,3.7,0,0,0,6.144-1.641,6.193,6.193,0,0,0,.167-1.118c.071-.676.341-1,1.008-1.017,1.073-.034,2.15-.052,3.222-.008.975.04,1.177.275,1.225,1.23a3.723,3.723,0,0,0,6.247,2.72c.791-.7,1.115-.732,1.882-.023.731.674,1.426,1.391,2.1,2.127.609.668.577,1.019-.032,1.707a3.679,3.679,0,0,0-.343,4.764,3.547,3.547,0,0,0,2.723,1.514c1.071.084,1.383.324,1.435,1.4s.012,2.149-.034,3.222c-.025.588-.388.863-1.053.891a3.779,3.779,0,0,0-3.489,2.111,3.665,3.665,0,0,0,.617,4.159c.683.747.719,1.114.048,1.837-.69.743-1.418,1.455-2.168,2.138-.674.615-1.072.58-1.756-.041a3.705,3.705,0,0,0-6.137,1.679,6.787,6.787,0,0,0-.163,1.18.931.931,0,0,1-.906.933C61.157,246.152,60.56,246.185,59.995,246.227Zm8.324-11.947a9.7,9.7,0,0,0-15.284-11.732,9.7,9.7,0,0,0,12.118,14.978.307.307,0,0,0-.044-.108c-1.32-1.33-2.637-2.665-3.976-3.976-.112-.11-.386-.1-.575-.077a17.241,17.241,0,0,1-1.935.287,5.908,5.908,0,0,1-5.821-7.277c.194-.785.707-.941,1.282-.378.784.767,1.572,1.532,2.326,2.328a1.178,1.178,0,0,0,1.117.378c1.745-.229,1.714-.225,2.027-1.96a1.226,1.226,0,0,0-.413-1.256c-.794-.757-1.561-1.542-2.319-2.334-.5-.517-.314-1.08.382-1.231a5.99,5.99,0,0,1,7.078,7.66.833.833,0,0,0,.049.678C65.631,231.607,66.964,232.924,68.319,234.28Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default History;
