import React, { useState, useEffect, useCallback, useRef } from "react";
import axios, { all } from "axios";
import {
  Menu,
  Sidebar as SideBar,
  Segment,
  Header,
  Table,
  Grid,
  Input,
  Button,
  Pagination,
  Dropdown,
  Select,
} from "semantic-ui-react";
import "../css/Main.css";
import imageCompression from "browser-image-compression";
import { Bar } from "react-chartjs-2";
import { ReactComponent as Cloud } from "../static/cloudy.svg";
import { ReactComponent as X } from "../static/X.svg";
import Home from "../static/home.svg";
import History from "../static/history.svg";
import Search from "../static/search.svg";
import Logout from "../static/logout.svg";
import { ReactComponent as Clipboard } from "../static/clipboard.svg";
import { ReactComponent as Power } from "../static/power.svg";
import { ReactComponent as Battery } from "../static/battery.svg";
import { ReactComponent as Capacity } from "../static/capacity.svg";
import { ReactComponent as Placeholder } from "../static/placeholder.svg";
import { ReactComponent as Placeholder2 } from "../static/placeholder2.svg";
import { ReactComponent as Placeholder3 } from "../static/placeholder3.svg";
import { ReactComponent as Placeholder4 } from "../static/placeholder4.svg";
import { ReactComponent as Placeholder5 } from "../static/placeholder5.svg";
import { ReactComponent as Placeholder6 } from "../static/placeholder6.svg";
import { ReactComponent as Placeholder7 } from "../static/placeholder7.svg";
import { ReactComponent as Complitive } from "../static/complitive.svg";
import { ReactComponent as Progressive } from "../static/progressive.svg";
import { ReactComponent as ModifyBtn } from "../static/modifybtn.svg";
import { ReactComponent as DeleteBtn } from "../static/deletebtn.svg";
import { ReactComponent as PlusBtn } from "../static/plusbtn.svg";
import { ReactComponent as RejectedIcon } from "../static/rejected.svg";
import { ReactComponent as PlusBtn2 } from "../static/plus.svg";
import { ReactComponent as RightArrow } from "../static/right_arrow.svg";
import { ReactComponent as RightArrowBtn } from "../static/right_arrow2.svg";
import { ReactComponent as LeftArrowBtn } from "../static/left_arrow.svg";
import DeletePopup from "./DeletePopup";
import CompletePopup from "./CompletePopup";
import IssueRegister from "./IssueRegister";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import As from "./As";
import Loading from "./Loading";
import Empty from "./empty";
import LoginPage from "./LoginPage";
import { useAuth } from "../AuthContext";
import extractSingleRegionData from "../utils/addressExtractor";
import statusColorPrinter from "../utils/statusColorPrinter";
import SimpleGeo from "./map/SimpleGeo";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const Main = (props) => {
  const { selectedIndex, setSelectedIndex, index, setIndex } = props;
  const {
    token,
    setToken,
    mainmain,
    allData,
    setAllData,
    setMainmain,
    setFacData,
    setIssueTotal,
    issueTotal,
    eventMainData,
    setEventMainData,
    setAsAllData,
    facData,
  } = useAuth();
  const [tempNoteList, setTempNoteList] = useState([]); //노트 리스트 진행상황에 뿌릴 때
  const [tempNoteAttachmentList, setTempNoteAttachmentList] = useState([]); // 첨부파일(사진) 리스트
  const [clickedDiv, setClickDiv] = useState(null); //이벤트현황 진행상황에서 누른 거에만 스타일 적용 시
  const [loading, setLoading] = useState(true); // api불러오기전에 잠깐 보여줄 로딩화면
  const [dailyLoading, setDailyLoading] = useState(true); // api불러오기전에 잠깐 보여줄 로딩화면
  const [mainData, setMainData] = useState([]);
  const [mainRefresh, setMainRefresh] = useState([]);
  const [findEnergyId, setfindEnergyId] = useState([]);
  const [solarLight, setSolarLight] = useState([]);
  const [solarHeat, setSolarHeat] = useState([]);
  const [solarLightToday, setSolarLightToday] = useState([]);
  const [solarHeatToday, setSolarHeatToday] = useState([]);
  const [issueData, setIssueData] = useState([]);
  const [faccurrentpage, setFaccurrentpage] = useState(1); //설비 검색 메인 페이지네이션
  const [addressFilter, setAddressFilter] = useState(""); //메인 주소 필터 value
  const [eventAddress, setEventAddress] = useState(""); // 이벤트 현황  주소 필터 value
  const [eventCurrentData, setEventCurrentData] = useState([]); //이벤트현황 테이블 표현 데이터
  const [eventSearchOn, setEventSearchOn] = useState(false); //이벤트현황 테이블 표현 데이터
  const [facSearchOn, setFacSearchOn] = useState(false); //설비검색 테이블 표현 데이터
  const [energySearchOn, setEnergySearchOn] = useState(false); //설비검색 에너지 테이블 표현 데이터
  const [energyCurrentData, setEnergyCurrentData] = useState([]); //설비검색 에너지 테이블 공통 데이터
  const [energyAllData, setEnergyAllData] = useState([]); //설비검색 에너지 테이블 검색 데이터
  const [eventAllData, setEventAllData] = useState([]); // 이벤트현황 공통으로 쓸 데이터
  const [facAllData, setFacAllData] = useState([]); // 설비검색 공통으로 쓸 데이터
  const [checkBroken, setCheckBroken] = useState(false);
  const [addFormMode, setAddFormMode] = useState(false); //작성모드, 읽기모드 전환 시
  const [belong, setBelong] = useState(""); //이벤트에서 소속
  const [name, setName] = useState(""); //이벤트에서 이름
  const [rank, setRank] = useState(""); //이벤트에서 직급
  const [contact, setContact] = useState(""); //이벤트에서 연락처
  const [selectStatusText, setSelectStatusText] = useState(""); // 상태 필터 선택한 값
  const [selectedStatus, setSelectedStatus] = useState(""); // 셀렉트박스 내가 선택한 거
  const [firstPhoneNum, setFirstPhoneNum] = useState("");
  const [secondPhoneNum, setSecondPhoneNum] = useState("");
  const [thirdPhoneNum, setThirdPhoneNum] = useState("");
  const [noteContent, setNoteContent] = useState(""); //노트 작성내용
  const [writeNote, setWriteNote] = useState(false); //작성 노트
  const [writeIssue, setWriteIssue] = useState(false); //작성 이슈
  const [rejected, setRejected] = useState(false);
  const [viewSlight, setViewSlight] = useState(true);
  const [viewSheat, setViewSheat] = useState(false);
  const [deleteCheck, setDeleteCheck] = useState(false); // 삭제 팝업
  const [defaultNote, setDefaultNote] = useState(""); //이벤트 현황에서 상태 누르기전 기본 노트값
  const [selectIssueId, setSelectIssueId] = useState("");
  const [selectEquipmentId, setSelectEquipmentId] = useState("");
  const [selectNoteId, setSelectNoteId] = useState("");
  const [eventStatusFilter, setEventStatusFilter] = useState(""); //상태 필터 value
  const [eventEnergyFilter, setEventEnergyFilter] = useState(""); //에너지 필터 value
  const [searchEventData, setSearchEventData] = useState(""); //이벤트 현황 검색 value
  const [inputId, setInputId] = useState("");
  const [isWLoaded, setIsWLoaded] = useState(false);
  const [currentEquipId, setCurrentEquipId] = useState("");
  const [breakIssue, setBreakIssue] = useState([]); //고장목록 이슈전체
  const [breakIssueList, setBreakIssueList] = useState([]); // 고장목록 이슈의 노트목록
  const [noteData, setNoteData] = useState("");
  const [imageUrls, setImageUrls] = useState(""); //미리보기 이미지

  useEffect(() => {
    const url = "https://api.the-kb.com";

    const fetchData = async () => {
      if (!token) return;

      try {
        const requests = [
          axios.get(`${url}/v1/just/all`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${url}/v1/equipment/solarlight/list`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${url}/v1/equipment/solarheat/list`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${url}/v1/issue/list`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ];
        const [justAll, solarlight, solarheat, issue] =
          await Promise.all(requests);

        const justAllData = justAll.data;
        const lightData = solarlight.data;
        const heatData = solarheat.data;

        setSolarLight(lightData);
        setSolarHeat(heatData);

        const issues = issue.data;
        const closeData = issues.filter((item) => item.status === "CLOSED");

        let allData = [];
        let mainData = [];

        justAllData.forEach((allItem) => {
          const light = lightData.find(
            (item) => item.id === allItem.equipmentId,
          );
          const heat = heatData.find((item) => item.id === allItem.equipmentId);

          if (light || heat) {
            //주소
            let addressData = "";
            if (light) {
              addressData = `${light.address.title} ${light.addressDetail}`;
            } else if (heat) {
              addressData = `${heat.address.title} ${heat.addressDetail}`;
            }

            //설비 용량
            let capacity = "";
            if (light) {
              capacity = light.capacity;
            } else if (heat) {
              capacity = heat.capacity;
            }

            allData.push({
              id: allItem.equipmentId,
              energy: `${allItem.energyType1}(${allItem.energyType2})`,
              owner: allItem.ownerTitle,
              addressData: addressData,
              capacity: capacity,
              installer: allItem.installerTitle,
              maintainer: allItem.maintainerTitle,
              status: allItem.status,
            });

            if (allItem.status.trim().toLowerCase() === "고장") {
              mainData.push({
                id: allItem.equipmentId,
                energy: `${allItem.energyType1}(${allItem.energyType2})`,
                owner: allItem.ownerTitle,
                addressData: addressData,
                capacity: capacity,
                installer: allItem.installerTitle,
                maintainer: allItem.maintainerTitle,
                status: allItem.status,
              });
            }

            // if (allItem.status === "고장" || allItem.status === "경고") {
            //   mainData.push({
            //     id: allItem.equipmentId,
            //     energy: `${allItem.energyType1}(${allItem.energyType2})`,
            //     owner: allItem.ownerTitle,
            //     addressData: addressData,
            //     capacity: capacity,
            //     installer: allItem.installerTitle,
            //     maintainer: allItem.maintainerTitle,
            //     status: allItem.status,
            //   });
            // }
          }
        });
        setAllData(allData);
        setMainmain(mainData);
        setMainRefresh(mainData);
        setEventMainData(mainData);
        setFacData(allData);
        setIssueTotal(issues);
        setAsAllData(closeData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [token]);

  useEffect(() => {
    // const asData = issueTotal.filter((item) => item.status === "CLOSED");
    // setAsAllData((prev) => [...prev, asData]);
    setAsAllData(issueTotal);
  }, [issueTotal]);

  const handleLogin = (id) => {
    setInputId(id);
  };

  useEffect(() => {
    if (index !== 0) {
      handleSearchEnergy(currentEquipId);
    }
  }, [currentEquipId]);

  const isNumeric = (value) => /^[0-9]*$/.test(value);

  const handleFirstPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 3);
    if (isNumeric(inputText)) {
      setFirstPhoneNum(inputText);
      updateContact(`${inputText}-${secondPhoneNum}-${thirdPhoneNum}`);
    }
  };

  const handleSecondPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 4);
    if (isNumeric(inputText)) {
      setSecondPhoneNum(inputText);
      updateContact(`${firstPhoneNum}-${inputText}-${thirdPhoneNum}`);
    }
  };

  const handleThirdPartChange = (e) => {
    const inputText = e.currentTarget.value.slice(0, 4);
    if (isNumeric(inputText)) {
      setThirdPhoneNum(inputText);
      updateContact(`${firstPhoneNum}-${secondPhoneNum}-${inputText}`);
    }
  };

  const updateContact = (newContact) => {
    setContact(newContact);
  };

  const options = [
    { key: "선택", text: "수리상태 선택" },
    { key: "OPENED", value: "접수", text: "접수" },
    { key: "WAITING_APPROVAL", value: "승인요청", text: "승인요청" },
    { key: "DISCARDED", value: "수리포기", text: "수리포기" },
    { key: "CLOSED", value: "수리완료", text: "수리완료" },
    { key: "IN_PROGRESS", value: "진행중", text: "진행중" },
    { key: "UNKNOWN", value: "알수없음", text: "알수없음" },
  ];

  const getOS = () => {
    const os = ["Windows", "Mac", "Linux"]; // add your OS values
    return os.find((v) => global.window?.navigator.platform.indexOf(v) >= 0);
  };
  const [loginUserRole, setLoginUserRole] = useState("");
  const [loginUserName, setLoginUserName] = useState("");
  const [loginUserTitle, setLoginUserTitle] = useState("");

  useEffect(() => {
    if (!token) return;

    async function getUserInfo(token) {
      const url = "https://api.the-kb.com/v1/user";
      try {
        if (token) {
          const res = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          let userData = res.data;

          setLoginUserRole(userData.role);
          setLoginUserName(userData.username);
          setLoginUserTitle(userData.title);

          return userData;
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    }

    getUserInfo(token);
  }, [token]);

  const labels = [];
  const today = new Date();

  for (let i = 0; i < 15; i++) {
    const date = new Date();
    date.setDate(today.getDate() - i);
    const day = date.getDate();
    labels.unshift(`${day}일`); // unshift로 날짜를 labels 배열의 앞쪽에 추가
  }

  //윤년 확인
  const isLeapYear = (year) => {
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
      return true;
    }
    return false;
  };

  //각 달의 일수 계산
  const getDay = (month, year) => {
    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;
      case 4:
      case 6:
      case 9:
      case 11:
        return 30;
      case 2:
        return isLeapYear(year) ? 29 : 28;
      default:
        throw new Error("일수 확인 불가");
    }
  };

  //최근 15일 전 날짜 계산
  const getFifteenDaysAgo = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    for (let i = 0; i < 14; i++) {
      day -= 1;
      if (day < 1) {
        month -= 1;
        if (month < 1) {
          month = 12;
          year -= 1;
        }
        day = getDay(month, year);
      }
    }
    return new Date(year, month - 1, day);
  };

  const fifteenDaysAgo = getFifteenDaysAgo(today);

  const formatDate = (date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0",
    )}-${String(date.getDate()).padStart(2, "0")}`;

  const todayformattedDate = formatDate(today);
  const fifteenDaysAgoformattedDate = formatDate(fifteenDaysAgo);

  useEffect(() => {
    const fetchDaily = async () => {
      const api = "https://api.the-kb.com";
      try {
        if (token) {
          const urls = [
            axios.get(
              `${api}/v1/record/daily/solarlight/${fifteenDaysAgoformattedDate}/${todayformattedDate}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
            axios.get(
              `${api}/v1/record/daily/solarheat/${fifteenDaysAgoformattedDate}/${todayformattedDate}`,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
          ];
          const [solarlightData, solarheatData] = await Promise.all(urls);

          const solar = solarlightData.data;
          const heat = solarheatData.data;

          const lightResult = [];
          const heatResult = [];

          solar.forEach((item) => {
            lightResult.push({
              date: item.date,
              accumulated: item.accumulated,
              delta: item.delta,
              efficiency: item.efficiency,
            });
          });

          heat.forEach((item) => {
            heatResult.push({
              date: item.date,
              accumulated: item.accumulated,
              delta: item.delta,
              efficiency: item.efficiency,
            });
          });

          setSolarLightToday(lightResult);
          setSolarHeatToday(heatResult);

          setDailyLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchDaily();
  }, [token]);

  let [justTotalData, setJustTotalData] = useState([]);
  let [lightTotalData, setLightTotalData] = useState([]);
  let [heatTotalData, setHeatTotalData] = useState([]);

  //금일, 누적, 총 설비용량
  const justTotalDataCallback = useCallback(async () => {
    const api = "https://api.the-kb.com";

    try {
      if (token) {
        const res = await axios.get(api + `/v1/just/total`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        let justTotal = res.data;
        setJustTotalData([justTotal]);
        setLightTotalData(res.data.solarLight.accumulatedToday);
        setHeatTotalData(res.data.solarHeat.accumulatedToday);
        setLoading(false);
      }
    } catch (error) {
      // console.error(error);
    }
  }, [token]);

  async function noteRegister(id) {
    const api = `https://api.the-kb.com/v1/issue/${id}`;

    if (
      selectStatusText === "" ||
      noteContent === "" ||
      belong === "" ||
      name === "" ||
      rank === "" ||
      contact === ""
    ) {
      alert("비어있는 항목값이 있습니다.");
      return null;
    }

    try {
      const res = await axios.post(
        api,
        {
          status: selectStatusText,
          text: noteContent,
          writer: {
            group: belong,
            name: name,
            title: rank,
            contact: contact,
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (res.status === 200 || res.status === 201) {
        const res = await axios.get(api, {
          headers: { Authorization: `Bearer ${token}` },
        });
        handleFileUpload(res.data.notes[res.data.notes.length - 1].id);
      }

      let tempNoteList = [...breakIssueList];
      tempNoteList = tempNoteList.filter((item) => item.id !== id);
      setBreakIssueList(tempNoteList);
      getNotesWithIssueId(id);
      alert("작성이 완료되었습니다.");
      setSelectStatusText("");
      setSelectedStatus("");
      setNoteContent("");
      setBelong("");
      setName("");
      setRank("");
      setContact("");
      setFirstPhoneNum("");
      setSecondPhoneNum("");
      setThirdPhoneNum("");
      setSelectedImage([]);
      fetchIssueCallback();
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!token) return;
    axios
      .get("https://api.the-kb.com/v1/issue/list", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setIssueTotal(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [issueTotal, token]); //

  const getNotesWithIssueId = async (issueId) => {
    if (!issueId) return;
    const response = await axios.get(
      `https://api.the-kb.com/v1/issue/${issueId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    setTempNoteList(response.data.notes);

    try {
      if (selectNoteId) {
        const responseAfter = await axios.get(
          `https://api.the-kb.com/v1/issue/note/${selectNoteId}`, //빈 노트시 여기서 get에러뜸
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setTempNoteAttachmentList(responseAfter.data.attachments);
        setNoteData(responseAfter.data);
      }
    } catch (error) {
      setTempNoteAttachmentList([]);
    }
  };

  //이슈 삭제
  async function deleteIssue(id) {
    const api = `https://api.the-kb.com/v1/issue/${id}`;

    const confirmDelete = window.confirm("삭제하시겠습니까?");
    if (!confirmDelete) {
      return;
    }

    try {
      const res = await axios.delete(api, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("수리내역이 삭제되었습니다.");
      fetchIssueCallback();
      let tempIssueList = [...breakIssue];
      tempIssueList = tempIssueList.filter((item) => item.id !== id);
      setBreakIssue(tempIssueList);
      return res;
    } catch (error) {
      console.log(error);
    }
  }

  //고장목록에 나올 이슈 목록
  async function handleBreakListCall(id) {
    setBreakIssue([]);
    setBreakIssueList([]);
    const api = `https://api.the-kb.com/v1/issue/list?equipmentId=${id}`;

    try {
      let res;
      if (id) {
        res = await axios.get(api, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBreakIssue(res.data);

        let note = res.data[0].notes;
        setBreakIssueList(note);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const justTotalDataLength = justTotalData.length;
  const accumulatedSlight =
    justTotalDataLength > 0
      ? parseInt(justTotalData[0].solarLight.accumulated)
      : "";
  const accumulatedSheat =
    justTotalDataLength > 0
      ? parseInt(justTotalData[0].solarHeat.accumulated)
      : "";

  //태양광 today값
  const todaySLightAccumulatedValue = (lightTotalData / 1000).toLocaleString(
    "ko-KR",
    {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    },
  );

  //태양열 today값
  const todaySHeatAccumulatedValue = (heatTotalData / 1000).toLocaleString(
    "ko-KR",
    {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    },
  );

  let data = {
    labels: labels,
    datasets: [
      {
        label: "발전량(MWh)",
        data: (viewSlight ? solarLightToday : solarHeatToday)
          .slice(0, 15)
          .map((value) =>
            (viewSlight ? value.delta / 100000 : value.delta / 100000).toFixed(
              1,
            ),
          ),
        backgroundColor: ["rgba(0, 204, 102, 0.6)"],
      },
    ],
  };

  const [currentpage, setcurrentpage] = useState(1); //메인페이지의 현재페이지 번호로 쓸꺼
  const [eventPage, setEventPage] = useState(1); //이벤트페이지의 현재페이지 번호로 쓸꺼

  const handleLogout = () => {
    setMainData([]);
    setInputId("");
    setToken("");
    window.location.reload();
  };

  const [energyInfoData, setEnergyInfoData] = useState([]);
  //에너지별 latest
  const latestDataCallback = useCallback(
    async (type, id) => {
      const api = "https://api.the-kb.com";
      try {
        let res;
        if (type === "solarlight") {
          res = await axios.get(api + `/v1/record/solarlight/${id}/latest`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } else if (type === "solarheat" && id >= 210) {
          res = await axios.get(api + `/v1/record/solarheat/${id}/latest`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }

        if (res) {
          const data = res.data;
          setEnergyInfoData(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [findEnergyId, token],
  );

  const [selectedImage, setSelectedImage] = useState([]); // 선택한 이미지의 URL을 저장할 상태

  const noteDataCallback = useCallback(
    async (noteId) => {
      const api = `https://api.the-kb.com/v1/issue/note/${noteId}`;
      const defaultApi = `https://api.the-kb.com/v1/issue/list`;

      try {
        let res;
        if (noteId) {
          res = await axios.get(api, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } else {
          res = await axios.get(defaultApi, {
            headers: { Authorization: `Bearer ${token}` },
          }); // 기본 데이터를 가져오는 API 호출
        }

        if (res.data) {
          setNoteData(res.data);
          setTempNoteAttachmentList(res.data.attachments);
        } else {
          const defaultData = defaultNote;
          setNoteData(defaultData[0]);
          setTempNoteAttachmentList(res.data.attachments);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [defaultNote, selectedImage, tempNoteAttachmentList, token],
  );

  const handleSearchNote = (i) => {
    noteDataCallback(i);
  };

  const fetchIssueCallback = useCallback(
    () => async (token) => {
      if (!token) return;

      const api = "https://api.the-kb.com";
      try {
        const res = await axios.get(api + "/v1/issue/list", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setIssueTotal(res.data);
        const asData = issueTotal.filter((item) => item.status === "CLOSED");
        setAsAllData(asData);
      } catch (error) {
        console.error(error);
      }
    },
    [token, issueTotal],
  );

  //메인페이지 데이터 표현할때 씀
  const startIndex = (currentpage - 1) * 10;
  const endIndex = startIndex + 10;
  const currentData = mainmain.slice(startIndex, endIndex);

  //메인화면 주소 필터
  const handleAddressFilter = (e, { value }) => {
    setcurrentpage(1);
    setAddressFilter(value);

    const statusFilteredData = allData.filter(
      // (item) => item.status === "고장" || item.status === "경고"
      (item) => item.status === "고장",
    );

    const addressFilterData = statusFilteredData.filter((item) =>
      item.addressData.includes(value),
    );

    if (value === "주소") {
      setMainmain(statusFilteredData);
    } else if (addressFilterData.length < 1) {
      setMainmain([]);
      setTotalPages(0);
    } else {
      // 필터링된 데이터가 있을 경우
      setMainmain(addressFilterData);

      setTotalPages(Math.ceil(addressFilterData.length / 10));
    }
  };

  const [asIndexItemsPerPage, setAsIndexItemsPerPage] = useState(3); //이벤트 index 페이지에 쓰임
  const eventStartIndex = (eventPage - 1) * asIndexItemsPerPage;
  const eventEndIndex = eventStartIndex + asIndexItemsPerPage;

  //이벤트 현황 주소 필터
  const handleEventAddress = (e, { value }) => {
    setEventPage(1);
    setEventAddress(value === "주소" ? "" : value);
  };

  // //이벤트현황 상태 필터
  const handleEventStatusFilter = (e, { value }) => {
    setEventPage(1);
    setEventStatusFilter(value === "상태" ? "" : value);
  };

  //이벤트현황 에너지 필터
  const handleEventEnergyFilter = (e, { value }) => {
    setEventPage(1);
    setEventEnergyFilter(value === "에너지원" ? "" : value);
  };

  useEffect(() => {
    let filterData = eventMainData;
    let issue = allData.filter((data) =>
      issueTotal.some((item) => item.equipmentId === data.id),
    );

    issue.forEach((issueItem) => {
      const existsInFilterData = filterData.some(
        (filterItem) => filterItem.id === issueItem.id,
      );
      if (!existsInFilterData) {
        filterData.push(issueItem);
      }
    });

    // 주소 필터링
    if (eventAddress) {
      filterData = filterData.filter((item) =>
        item.addressData.includes(eventAddress),
      );
    }

    // 에너지 필터링
    if (eventEnergyFilter && eventEnergyFilter !== "에너지원") {
      filterData = filterData.filter(
        (item) => item.energy === eventEnergyFilter,
      );
    }

    // 상태 필터링
    if (eventStatusFilter && eventStatusFilter !== "상태") {
      const statusFilterData = issueTotal.filter(
        (item) => item.status === eventStatusFilter,
      );
      filterData = filterData.filter((item) =>
        statusFilterData.some((data) => data.equipmentId === item.id),
      );
    }

    // 검색 필터링
    if (eventSearchOn) {
      const searchTextLower = searchEventData.toLowerCase();
      filterData = filterData.filter((data) => {
        const addressMatch = data.addressData
          .toLowerCase()
          .includes(searchTextLower);
        const energyMatch =
          data.energy && data.energy.toLowerCase().includes(searchTextLower);
        const ownerMatch =
          data.owner && data.owner.toLowerCase().includes(searchTextLower);
        const installerMatch =
          data.installer &&
          data.installer.toLowerCase().includes(searchTextLower);
        const maintainerMatch =
          data.maintainer &&
          data.maintainer.toLowerCase().includes(searchTextLower);

        let status;
        const statusData = issueTotal.find(
          (item) => item.equipmentId === data.id,
        );
        if (statusData) {
          status = options
            .find((option) => option.key === statusData.status)
            ?.text.toLowerCase();
        } else {
          status = data.status?.toLowerCase();
        }
        const statusMatch = status && status.includes(searchTextLower);

        return (
          addressMatch ||
          energyMatch ||
          ownerMatch ||
          installerMatch ||
          maintainerMatch ||
          statusMatch
        );
      });
    }

    const currentEventData = filterData.slice(eventStartIndex, eventEndIndex);

    setEventAllData(filterData); // 필터링된 전체 데이터 업데이트
    setEventCurrentData(currentEventData); // 현재 페이지에 보여질 데이터 업데이트
  }, [
    eventAddress,
    eventEnergyFilter,
    eventStatusFilter,
    searchEventData,
    eventMainData,
    eventStartIndex,
    eventEndIndex,
    eventSearchOn,
    issueTotal,
  ]);

  useEffect(() => {
    if (searchEventData === "") {
      setEventSearchOn(false);
    }
  }, [searchEventData]);

  const [eventTotalPages, setEventTotalPages] = useState(
    eventAddress || eventStatusFilter || eventEnergyFilter || searchEventData
      ? Math.ceil(eventAllData.length / asIndexItemsPerPage)
      : Math.ceil(eventMainData.length / asIndexItemsPerPage),
  );
  // 이벤트 토탈 페이지

  //설비 전체 검색 데이터
  const [searchFacData, setSearchFacData] = useState("");

  const [facIndexItemsPerPage, setFacIndexItemsPerPage] = useState(3); //설비 index 페이지에 쓰임
  const [facIndexSearchItemsPerPage, setFacIndexSearchItemsPerPage] =
    useState(3); // 에너지 index 페이지에 쓰임

  //설비검색 데이터
  const facStartIndex = (faccurrentpage - 1) * 10;
  const facEndIndex = facStartIndex + 10;

  useEffect(() => {
    let filterData = allData;

    // 검색 필터링
    if (facSearchOn) {
      setFaccurrentpage(1);
      const searchTextLower = searchFacData.toLowerCase();
      filterData = filterData.filter((data) => {
        const addressMatch = data.addressData
          .toLowerCase()
          .includes(searchTextLower);
        const energyMatch =
          data.energy && data.energy.toLowerCase().includes(searchTextLower);
        const ownerMatch =
          data.owner && data.owner.toLowerCase().includes(searchTextLower);
        const installerMatch =
          data.installer &&
          data.installer.toLowerCase().includes(searchTextLower);
        const maintainerMatch =
          data.maintainer &&
          data.maintainer.toLowerCase().includes(searchTextLower);
        const statusMatch =
          data.status && data.status.toLowerCase().includes(searchTextLower);

        return (
          addressMatch ||
          energyMatch ||
          ownerMatch ||
          installerMatch ||
          maintainerMatch ||
          statusMatch
        );
      });
    }

    const currentEventData = filterData.slice(facStartIndex, facEndIndex);

    setFacData(filterData);
    setFacAllData(currentEventData);
  }, [searchFacData, allData, facStartIndex, facEndIndex, facSearchOn]);

  useEffect(() => {
    if (searchFacData === "") {
      setFacSearchOn(false);
    }
  }, [searchFacData]);

  const [facTotalPages, setFacTotalPages] = useState(
    searchFacData
      ? Math.ceil(facData.length / 10)
      : Math.ceil(allData.length / 10),
  ); //설비 페이지 토탈

  const handleMainRefresh = () => {
    setMainmain(mainRefresh);
    setAddressFilter("");
    setcurrentpage(1);
  };

  const handleEventRefresh = () => {
    setEventPage(1);
    setAsDetail(false);
    setBreakList(false);
    setSearchEventData("");
    setEventAddress("");
    fetchIssueCallback(); //새로고침 시 업데이트된 이슈 등록위해
    setAsIndexItemsPerPage(10);
    setNoteData("");
    setEventStatusFilter("");
    setEventEnergyFilter("");
  };

  //설비 메인 새로고침
  const handleFacRefresh = () => {
    setFaccurrentpage(1);
    setSearchFacData("");
    setFilterFacData([]);
    // fetchDataCallback();
    // setIndex(0);
  };

  //설비 에너지별 새로고침
  const handleEnergyRefresh = () => {
    setEnergycurrentpage(1);
    setFacIndexItemsPerPage(10);
    setEnergyDetail(false);
    setSearchEnergyData("");
    setFilterEnergyData([]);
    // fetchDataCallback();
  };

  //날씨 데이터 가져오기
  const [weatherData, setWeatherData] = useState({});

  useEffect(() => {
    const WeatherInfo = () => {
      const getWeatherInfo = (latitude, longitude) => {
        //weathermap api key 입력
        const apiKey = "6f17dc02104dc260711eedfa116da1be";
        const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`;
        const weatherMap = [
          { 201: "비를 동반한 천둥구름" },
          { 200: "비를 동반한 천둥구름" },
          { 202: "폭우를 동반한 천둥구름" },
          { 210: "약한 천둥구름" },
          { 211: "천둥구름" },
          { 212: "강한 천둥구름" },
          { 221: "불규칙적 천둥구름" },
          { 230: "연무를 동반한 천둥구름" },
          { 231: "연무를 동반한 천둥구름" },
          { 232: "비를 동반한 천둥구름" },
          { 300: "가벼운 안개비" },
          { 301: "안개비" },
          { 302: "강한 안개비" },
          { 310: "가벼운 적은비" },
          { 311: "적은비" },
          { 312: "강한 적은비" },
          { 313: "소나기와 안개비" },
          { 314: "강한 소나기와 안개비" },
          { 321: "소나기" },
          { 500: "약한 비" },
          { 501: "중간 비" },
          { 502: "강한 비" },
          { 503: "매우 강한 비" },
          { 504: "극심한 비" },
          { 511: "우박" },
          { 520: "약한 소나기 비" },
          { 521: "소나기 비" },
          { 522: "강한 소나기 비" },
          { 531: "불규칙적 소나기 비" },
          { 600: "가벼운 눈" },
          { 601: "눈" },
          { 602: "강한 눈" },
          { 611: "진눈깨비" },
          { 612: "소나기 진눈깨비" },
          { 615: "약한 비와 눈" },
          { 616: "비와 눈" },
          { 620: "약한 소나기 눈" },
          { 621: "소나기 눈" },
          { 622: "강한 소나기 눈" },
          { 701: "박무" },
          { 711: "연기" },
          { 721: "연무" },
          { 731: "모래 먼지" },
          { 741: "안개" },
          { 751: "모래" },
          { 761: "먼지" },
          { 762: "화산재" },
          { 771: "돌풍" },
          { 781: "토네이도" },
          { 800: "구름 없는 맑은 하늘" },
          { 801: "약간의 구름이 낀 하늘" },
          { 802: "구름이 낀 하늘" },
          { 803: "구름이 거의 없는 하늘" },
          { 804: "흐린 하늘" },
          { 900: "토네이도" },
          { 901: "태풍" },
          { 902: "허리케인" },
          { 903: "한랭" },
          { 904: "고온" },
          { 905: "바람부는" },
          { 906: "우박" },
          { 951: "바람이 거의 없는" },
          { 952: "약한 바람" },
          { 953: "부드러운 바람" },
          { 954: "중간 세기 바람" },
          { 955: "신선한 바람" },
          { 956: "센 바람" },
          { 957: "돌풍에 가까운 센 바람" },
          { 958: "돌풍" },
          { 959: "심각한 돌풍" },
          { 960: "폭풍" },
          { 961: "강한 폭풍" },
          { 962: "허리케인" },
        ];
        axios
          .get(apiUrl)
          .then((res) => {
            if (res) {
              setIsWLoaded(true);
            }
            var versers = res.data.weather[0]["id"].toString();
            var status = weatherMap.filter(
              (obj) => Object.keys(obj)[0] === versers,
            );
            var cloudStatus = Object.values(status[0])[0];
            setWeatherData({
              sunrise: res.data.sys.sunrise,
              sunset: res.data.sys.sunset,
              weather: cloudStatus,
              temperature: res.data.main.temp,
              wind: res.data.wind.speed,
              humidity: res.data.main.humidity,
              precipitation:
                res.data.rain?.["1h"] || res.data.snow?.["1h"] || 0,
            });
          })
          .catch((error) => console.log(error));
      };
      const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              getWeatherInfo(latitude, longitude);
            },
            (error) => {
              console.log(error);
            },
          );
        } else {
          console.log("위치 기반을 정보를 지원하지 않습니다.");
        }
      };
      getLocation();
    };
    WeatherInfo();
    const interval = setInterval(WeatherInfo, 900000); // 15분마다 데이터 가져오기

    return () => clearInterval(interval);
  }, []);

  // fetchDataCallback을 사용하여 데이터 가져오기
  useEffect(() => {
    // fetchDataCallback();
    fetchIssueCallback();
    justTotalDataCallback();
    const interval = setInterval(() => {
      // fetchDataCallback();
      justTotalDataCallback();
      fetchIssueCallback();
      setcurrentpage(1);
      setEventPage(1);
    }, 900000); // 15분마다 데이터 가져오기
    return () => clearInterval(interval);
  }, [
    // fetchDataCallback,
    token,
  ]);

  const [asDetail, setAsDetail] = useState(false);
  const [breakList, setBreakList] = useState(false);
  const [selectedAsId, setSelectedAsId] = useState(null);
  const [selectedAsName, setSelectedAsName] = useState([]); //이슈 발생 사용자 이름

  //이벤트현황에서 오리지날
  const handleSearchAs = (id) => {
    const matchingDataName = eventMainData.filter((item) => item.id === id);
    const machingData = issueTotal.filter((item) => item.equipmentId === id);
    // const notes = machingData[0].notes;
    const notes = machingData.notes;
    // const lastData = notes[notes.length - 1];
    const lastData = notes;
    setDefaultNote(lastData);

    if (matchingDataName) {
      setSelectedAsName(matchingDataName[0].owner);
    }

    setSelectedAsId(id);

    if (id === selectedAsId) {
      setAsIndexItemsPerPage(() => {
        if (asDetail || breakList) {
          setEventPage(1);
          setBreakList(false);
          setAsDetail(false);
          return 10;
        } else if (!breakList || !asDetail) {
          setBreakList(true);
          return 3;
        } else {
          setEventPage(1);
          setBreakList(false);
          return 10;
        }
      });
    } else {
      setBreakList(true);
      setAsDetail(false);
      setAsIndexItemsPerPage(3);
    }
  };

  //에너지 이미지
  const [selectedEnergyId, setSelectedEnergyId] = useState(null); //id값 담으려고 만듦
  const [selectedEnergyName, setSelectedEnergyName] = useState([]); //실시간 계측 정보

  const handleSearchEnergy = (id) => {
    const matchingData = allData.filter((item) => item.id === id);
    let energyType = matchingData[0]?.energy;

    if (energyType === "태양열(자연식)" || energyType === "태양열(강제식)") {
      energyType = "solarheat";
    } else if (energyType === "태양광(단상)" || energyType === "태양광(삼상)") {
      energyType = "solarlight";
    }

    if (matchingData) {
      setSelectEnergyInfo(matchingData?.[0]);
      setSelectedEnergyName(matchingData?.[0]);
    }

    setSelectedEnergyId(id);
    setEnergyDetail(id === selectedEnergyId ? !energyDetail : true);

    latestDataCallback(energyType, id);

    setSelectedEnergyId(id);

    // //밑의 함수에서 유동적인 토탈페이지에 쓰려고 만듦
    const pageCounts = {
      index1Page: Math.ceil(
        allData.filter(
          (item) =>
            item.energy === "태양광(단상)" || item.energy === "태양광(삼상)",
        ).length / 10,
      ), //index1,
      index2Page: Math.ceil(
        allData.filter((item) => item.energy === "태양열(자연식)").length / 10,
      ), //index2
      index3Page: Math.ceil(
        allData.filter((item) => item.energy === "태양열(강제식)").length / 10,
      ), //index3
    };

    const pageCount = pageCounts[`index${index}Page`];

    if (id === selectedEnergyId) {
      energyDetail === true
        ? energyDetail === true
          ? setEnergyDetail(!energyDetail)
          : setEnergyDetail(energyDetail)
        : setEnergyDetail(!energyDetail);
      energyDetail === true
        ? energycurrentpage > pageCount
          ? setFacIndexItemsPerPage(() => {
              setEnergycurrentpage(1);
              return 10;
            })
          : setFacIndexItemsPerPage(10)
        : setFacIndexItemsPerPage(3);
    } else {
      energyDetail === true
        ? setEnergyDetail(energyDetail)
        : setEnergyDetail(!energyDetail);
      energyDetail === true
        ? setFacIndexItemsPerPage(3)
        : id !== selectedEnergyId || selectedEnergyId === null
          ? setFacIndexItemsPerPage(3)
          : setFacIndexItemsPerPage(10);
    }
  };

  const handleFilterSearchEnergy = (id) => {
    setSelectEnergyInfo([]);
    setEnergyInfoData([]);
    const matchingData = energyCurrentData.filter((item) => item.id === id);
    let energyType = matchingData[0]?.energy;

    if (energyType === "태양열(자연식)" || energyType === "태양열(강제식)") {
      energyType = "solarheat";
    } else if (energyType === "태양광(단상)" || energyType === "태양광(삼상)") {
      energyType = "solarlight";
    }

    if (matchingData) {
      setSelectEnergyInfo(matchingData?.[0]);
    }

    setSelectedEnergyId(id);
    setEnergyDetail(id === selectedEnergyId ? !energyDetail : true);

    latestDataCallback(energyType, id);

    setSelectedEnergyId(id);

    //밑의 함수에서 유동적인 토탈페이지에 쓰려고 만듦
    const searchCounts = {
      index1Page: Math.ceil(
        energyCurrentData.filter(
          (item) =>
            item.energy === "태양광(단상)" || item.energy === "태양광(삼상)",
        ).length / 10,
      ), //index1,
      index2Page: Math.ceil(
        energyCurrentData.filter((item) => item.energy === "태양열(자연식)")
          .length / 10,
      ), //index2
      index3Page: Math.ceil(
        energyCurrentData.filter((item) => item.energy === "태양열(강제식)")
          .length / 10,
      ), //index3
    };

    const searchCount = searchCounts[`index${index}Page`];

    if (id === selectedEnergyId) {
      energyDetail === true
        ? energyDetail === true
          ? setEnergyDetail(!energyDetail)
          : setEnergyDetail(energyDetail)
        : setEnergyDetail(!energyDetail);
      energyDetail === true
        ? energySearchPage > searchCount
          ? setFacIndexSearchItemsPerPage(() => {
              setEnergySearchPage(1);
              return 10;
            })
          : setFacIndexSearchItemsPerPage(10)
        : setFacIndexSearchItemsPerPage(3);
    } else {
      energyDetail === true
        ? setEnergyDetail(energyDetail)
        : setEnergyDetail(!energyDetail);
      energyDetail === true
        ? setFacIndexSearchItemsPerPage(3)
        : id !== selectedEnergyId
          ? setFacIndexSearchItemsPerPage(3)
          : setFacIndexSearchItemsPerPage(10);
    }
  };

  //메인페이지 대시보드 정상,고장 항목
  const solarLightStatus = allData.filter((item) =>
    solarLight.some((data) => data.id === item.id),
  );
  const solarHeatStatus = allData.filter((item) =>
    solarHeat.some((data) => data.id === item.id),
  );
  // console.log(solarLightStatus);

  const filterNice = viewSlight
    ? solarLightStatus.filter(
        (item) => item.status === "정상" || item.status === "운영준비중",
      )
    : solarHeatStatus.filter(
        (item) => item.status === "정상" || item.status === "운영준비중",
      );

  // console.log(filterNice);

  const filterReady = viewSlight
    ? solarLightStatus.filter((item) => item.status === "경고")
    : solarHeatStatus.filter((item) => item.status === "경고");

  const filterBad = viewSlight
    ? solarLightStatus.filter((item) => item.status === "고장")
    : solarHeatStatus.filter((item) => item.status === "고장");
  //메인페이지 대시보드 정상,고장 항목

  // const filteredMainData = viewSlight ?
  //   solarLight.filter(
  //       (data) => data.status === "고장" || data.status === "경고"
  //     )
  //   : solarHeat.filter(
  //       (data) => data.status === "고장" || data.status === "경고"
  //     );

  const [totalPages, setTotalPages] = useState(Math.ceil(mainmain.length / 10));
  //메인 토탈 페이지

  //에너지 검색 데이터
  const [searchEnergyData, setSearchEnergyData] = useState(""); // user가 입력한 찾고싶은 값
  const [filterEnergyData, setFilterEnergyData] = useState([]); // 찾고싶은 값이 필터링되어 만들어지는 리스트

  //에너지당 상세 페이지네이션 관련
  const [energycurrentpage, setEnergycurrentpage] = useState(1);
  const energyStartIndex = (energycurrentpage - 1) * facIndexItemsPerPage;
  const energyEndIndex = energyStartIndex + facIndexItemsPerPage;
  //에너지당 상세 페이지네이션 관련

  //에너지 인덱스당 필터먹은 데이터 첫페이지에 표시되는것
  useEffect(() => {
    let energyData; //에너지 각각 데이터
    let energySearch; //에너지 각 검색

    const energyCurrentData1 = allData.filter(
      (item) =>
        item.energy === "태양광(단상)" || item.energy === "태양광(삼상)",
    );
    const energyCurrentData2 = allData.filter(
      (item) => item.energy === "태양열(자연식)",
    );
    const energyCurrentData3 = allData.filter(
      (item) => item.energy === "태양열(강제식)",
    );
    const energyCurrentData4 = mainData.filter(
      (item) => item.energyType2 === "지열",
    );
    const energyCurrentData5 = mainData.filter(
      (item) => item.energyType2 === "풍력",
    );
    const energyCurrentData6 = mainData.filter(
      (item) => item.energyType2 === "연료 전지",
    );
    const energyCurrentData7 = mainData.filter(
      (item) => item.energyType2 === "ESS",
    );

    if (index === 1) {
      energyData = energyCurrentData1;
    } else if (index === 2) {
      energyData = energyCurrentData2;
    } else if (index === 3) {
      energyData = energyCurrentData3;
    } else if (index === 4) {
      energyData = energyCurrentData4;
    } else if (index === 5) {
      energyData = energyCurrentData5;
    } else if (index === 6) {
      energyData = energyCurrentData6;
    } else if (index === 7) {
      energyData = energyCurrentData7;
    }

    setEnergySearchPage(1);

    if (energySearchOn) {
      const searchEnergyDataLower = String(searchEnergyData).toLowerCase();
      const filteredData = energyData.filter((item) => {
        const energyMatch =
          item.energy &&
          item.energy.toLowerCase().includes(searchEnergyDataLower);
        const installerMatch =
          item.installer &&
          item.installer.toLowerCase().includes(searchEnergyDataLower);
        const maintainerMatch =
          item.maintainer &&
          item.maintainer.toLowerCase().includes(searchEnergyDataLower);
        const ownerTitleMatch =
          item.owner &&
          item.owner.toLowerCase().includes(searchEnergyDataLower);
        const statusMatch =
          item.status && item.status.includes(searchEnergyDataLower);
        const addressMatch =
          item.addressData &&
          item.addressData.toLowerCase().includes(searchEnergyDataLower);

        return (
          energyMatch ||
          installerMatch ||
          maintainerMatch ||
          ownerTitleMatch ||
          statusMatch ||
          addressMatch
        );
      });
      energySearch = filteredData;
    }
    // setEnergySearchData(energySearch);
    if (index !== 0) {
      if (energySearchOn) {
        setEnergyCurrentData(
          energySearch.slice(energyStartIndex, energyEndIndex),
        );
      } else {
        setEnergyCurrentData(
          energyData.slice(energyStartIndex, energyEndIndex),
        );
      }
    }
    if (index !== 0) {
      if (energySearchOn) {
        setEnergyAllData(energySearch);
      }
    }
  }, [
    index,
    allData,
    energyStartIndex,
    energyEndIndex,
    searchEnergyData,
    energySearchOn,
  ]);

  useEffect(() => {
    if (searchEnergyData === "") {
      setEnergySearchOn(false);
      setEnergycurrentpage(1);
    }
  }, [searchEnergyData]);

  //설비 페이지 인덱스별 페이지네이션  (Pagination에 직접)
  const pageCount1 = Math.ceil(
    energySearchOn
      ? energyAllData.length / facIndexItemsPerPage
      : allData.filter(
          (item) =>
            item.energy === "태양광(단상)" || item.energy === "태양광(삼상)",
        ).length / facIndexItemsPerPage,
  ); //index1
  const pageCount2 = Math.ceil(
    energySearchOn
      ? energyAllData.length / facIndexItemsPerPage
      : allData.filter((item) => item.energy === "태양열(자연식)").length /
          facIndexItemsPerPage,
  ); //index2
  const pageCount3 = Math.ceil(
    energySearchOn
      ? energyAllData.length / facIndexItemsPerPage
      : allData.filter((item) => item.energy === "태양열(강제식)").length /
          facIndexItemsPerPage,
  ); //index3
  const pageCount4 = Math.ceil(
    mainData.filter((item) => item.energyType2 === "지열").length /
      facIndexItemsPerPage,
  ); //index4
  const pageCount5 = Math.ceil(
    mainData.filter((item) => item.energyType2 === "풍력").length /
      facIndexItemsPerPage,
  ); //index5
  const pageCount6 = Math.ceil(
    mainData.filter((item) => item.energyType2 === "연료 전지").length /
      facIndexItemsPerPage,
  ); //index6
  const pageCount7 = Math.ceil(
    mainData.filter((item) => item.energyType2 === "ESS").length /
      facIndexItemsPerPage,
  ); //index7
  //설비 페이지 인덱스별 페이지네이션

  //에너지당 검색 페이지네이션 관련
  const [energySearchPage, setEnergySearchPage] = useState(1);
  const energySearchStartIndex =
    (energySearchPage - 1) * facIndexSearchItemsPerPage;
  const energySearchEndIndex =
    energySearchStartIndex + facIndexSearchItemsPerPage;
  //에너지당 검색 페이지네이션 관련
  const energySearchCurrentData1 = filterEnergyData
    .filter(
      (item) =>
        item?.energy === "태양광(단상)" || item?.energyType2 === "태양광(삼상)",
    )
    .slice(energySearchStartIndex, energySearchEndIndex);
  const energySearchCurrentData2 = filterEnergyData
    .filter((item) => item?.energy === "태양열(자연식)")
    .slice(energySearchStartIndex, energySearchEndIndex);
  const energySearchCurrentData3 = filterEnergyData
    .filter((item) => item?.energy === "태양열(강제식)")
    .slice(energySearchStartIndex, energySearchEndIndex);
  const energySearchCurrentData4 = filterEnergyData
    .filter((item) => item?.energyType2 === "지열")
    .slice(energySearchStartIndex, energySearchEndIndex);
  const energySearchCurrentData5 = filterEnergyData
    .filter((item) => item?.energyType2 === "풍력")
    .slice(energySearchStartIndex, energySearchEndIndex);
  const energySearchCurrentData6 = filterEnergyData
    .filter((item) => item?.energyType2 === "연료 전지")
    .slice(energySearchStartIndex, energySearchEndIndex);
  const energySearchCurrentData7 = filterEnergyData
    .filter((item) => item?.energyType2 === "ESS")
    .slice(energySearchStartIndex, energySearchEndIndex);
  //에너지 인덱스당 필터먹은 데이터 첫페이지에 표시되는것

  //에너지 검색 인덱스별 페이지네이션
  const searchCount1 = Math.ceil(
    filterEnergyData.filter(
      (item) =>
        item?.energy === "태양광(단상)" || item?.energy === "태양광(삼상)",
    ).length / facIndexSearchItemsPerPage,
  ); //index1
  const searchCount2 = Math.ceil(
    filterEnergyData.filter((item) => item?.energy === "태양열(자연식)")
      .length / facIndexSearchItemsPerPage,
  ); //index2
  const searchCount3 = Math.ceil(
    filterEnergyData.filter((item) => item?.energy === "태양열(강제식)")
      .length / facIndexSearchItemsPerPage,
  ); //index3
  const searchCount4 = Math.ceil(
    filterEnergyData.filter((item) => item?.energyType2 === "지열").length /
      facIndexSearchItemsPerPage,
  ); //index4
  const searchCount5 = Math.ceil(
    filterEnergyData.filter((item) => item?.energyType2 === "풍력").length /
      facIndexSearchItemsPerPage,
  ); //index5
  const searchCount6 = Math.ceil(
    filterEnergyData.filter((item) => item?.energyType2 === "연료 전지")
      .length / facIndexSearchItemsPerPage,
  ); //index6
  const searchCount7 = Math.ceil(
    filterEnergyData.filter((item) => item?.energyType2 === "ESS").length /
      facIndexSearchItemsPerPage,
  ); //index7
  //에너지 검색 인덱스별 페이지네이션

  //메인 페이지 번호 클릭 호출
  const handleMainChange = (e, { activePage }) => {
    setcurrentpage(activePage);
  };
  //메인 페이지 번호 클릭 호출

  //이벤트 페이지 번호 클릭 호출
  const handleEventChange = (e, { activePage }) => {
    setEventPage(activePage);
  };
  //이벤트 페이지 번호 클릭 호출

  //설비 페이지 번호 클릭할 때 마다 호출됨
  const handlePageChange = (e, { activePage }) => {
    setFaccurrentpage(activePage);
  };
  //설비 페이지 번호 클릭할 때 마다 호출됨

  //에너지 상세 페이지 번호 클릭할 때마다 호출됨

  const handleEnergyChange = (e, { activePage }) => {
    setEnergycurrentpage(activePage);
  };
  //에너지 상세 페이지 번호 클릭할 때마다 호출됨

  //에너지 검색 상세 페이지 번호 클릭할 때마다 호출됨
  const handleSearchEnergyChange = (e, { activePage }) => {
    setEnergySearchPage(activePage);
  };
  //에너지 검색 상세 페이지 번호 클릭할 때마다 호출됨

  const [filterFacData, setFilterFacData] = useState([]);
  //설비 전체 검색 데이터

  //토탈 페이지 나타냄(메인이랑 설비검색 첫페이지 나오는 내용 같아서 같이해둠)
  useEffect(() => {
    latestDataCallback();
    setFacTotalPages(
      searchFacData
        ? Math.ceil(facData.length / 10)
        : Math.ceil(allData.length / 10),
    );
    setTotalPages(Math.ceil(mainmain.length / 10));
    setEventTotalPages(
      eventAddress || eventStatusFilter || eventEnergyFilter || searchEventData
        ? Math.ceil(eventAllData.length / asIndexItemsPerPage)
        : Math.ceil(eventMainData.length / asIndexItemsPerPage),
    );
  }, [
    mainData,
    eventEnergyFilter,
    eventAllData,
    eventAddress,
    issueTotal,
    asIndexItemsPerPage,
    eventStatusFilter,
    searchEventData,
    eventMainData,
    latestDataCallback,
    viewSlight,
    facData,
    searchFacData,
    searchEnergyData,
    allData,
    facIndexItemsPerPage,
  ]);
  //토탈 페이지 나타냄(메인이랑 설비검색 첫페이지 나오는 내용 같아서 같이해둠)

  const [time, setTime] = useState(new Date().toLocaleDateString());
  const [selectEnergyInfo, setSelectEnergyInfo] = useState([]); // 선택한 이미지의 값을 표에 가져옴
  const [energyDetail, setEnergyDetail] = useState(false); //페이지 온오프 기능으로 씀  (메인,설비에서)
  // 설비 검색 핸들러

  const handleAll = () => {
    setFilterFacData([]);
    setIndex(0);
  };

  const handleClick = (index) => {
    setEnergySearchPage(1);
    setEnergycurrentpage(1);
    setEnergyDetail(false);
    setIndex(index);
  };

  // 설비 검색 핸들러

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleString());
    }, 1000); //1초마다 날짜 표시

    return () => clearInterval(intervalId);
  }, []);

  const handleSelectedIndex = (index) => {
    setSelectedIndex(index);
  };

  const [searchTotalFacPages, setSearchTotalFacPages] = useState(
    Math.ceil(filterFacData.length / 10),
  );
  const [searchFacPages, setSearchFacPages] = useState(1); //설비 서치후 현재 페이지
  //설비 전체(현재 페이지)

  //설비에서 검색 관련
  let searchFacStartIndex = (searchFacPages - 1) * 10;
  let searchFacEndIndex = searchFacStartIndex + 10;
  //설비에서 검색 관련

  //설비에서 검색 토탈 페이지
  const filterDataLength = filterFacData.length;

  useEffect(() => {
    setFacIndexSearchItemsPerPage(facIndexSearchItemsPerPage);
    setSearchTotalFacPages(Math.ceil(filterDataLength / 10));
  }, [filterDataLength, energyDetail]);

  const searchFacPageChange = (e, { activePage }) => {
    setSearchFacPages(activePage);
  };

  const buttons = [
    { index: 0, label: "설비 메인" },
    { index: 1, label: "단상/삼상" },
    { index: 2, label: "자연식" },
    { index: 3, label: "강제식" },
    { index: 4, label: "지열" },
    { index: 5, label: "풍력" },
    { index: 6, label: "연료전지" },
    { index: 7, label: "ESS" },
  ];

  const handlePreview = async (attachmentId) => {
    const api = `https://api.the-kb.com/v1/issue/files/${attachmentId}`;

    try {
      const response = await axios.get(api, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      });
      const blob = response.data;
      const imageUrl = URL.createObjectURL(blob);
      setImageUrls((prevUrls) => ({
        ...prevUrls,
        [attachmentId]: imageUrl,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const openImagePreview = (attachmentId) => {
    const imageUrl = imageUrls[attachmentId];
    if (imageUrl) {
      window.open(imageUrl);
    }
  };

  useEffect(() => {
    tempNoteAttachmentList.forEach((attachment) => {
      if (!imageUrls[attachment.id]) {
        handlePreview(attachment.id);
      }
    });
  }, [tempNoteAttachmentList, token, handlePreview, imageUrls]);

  //이미지 삭제 핸들러
  const handleImageDelete = (imgIndex) => {
    setSelectedImage((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(imgIndex, 1);
      fileInputRef.current.value = null;
      return newImages;
    });
  };

  const handleImgDelete = async (attachMentId) => {
    const api = `https://api.the-kb.com/v1/issue/attachment/${attachMentId}`;

    try {
      const res = await axios.delete(api, {
        headers: { Authorization: `Bearer ${token}` },
      });
      getNotesWithIssueId(selectIssueId);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const fileInputRef = useRef(null); //파일 미리보기에
  const secondFileInputRef = useRef(null); //파일 업로드에

  const handleFileUpload = async (noteId) => {
    const api = `https://api.the-kb.com/v1/issue/note/${noteId}`; //노트 id
    const files = fileInputRef.current.files;

    try {
      const responses = await Promise.all(
        Array.from(files).map(async (file) => {
          let compressedFile = file;
          if (file.type.startsWith("image/") && file.size > 1000 * 1024) {
            // 1000KB 이상이면 압축
            const options = {
              maxSizeMB: 1, // 최대 파일 크기
              maxWidthOrHeight: 1920, // 이미지의 최대 너비 또는 높이
              useWebWorker: true,
            };
            try {
              compressedFile = await imageCompression(file, options);
            } catch (compressionError) {
              console.error("Image compression error:", compressionError);
            }
          }

          const formData = new FormData();
          formData.append("file", compressedFile);

          const res = await axios.post(api, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          });
          getNotesWithIssueId(selectIssueId);
          return res;
        }),
      );
      return responses;
    } catch (error) {
      console.error(error);
    }
  };

  const handleImagePreview = async (e) => {
    const files = Array.from(e.target.files);

    const imagePreviews = await Promise.all(
      files.map(async (file) => {
        if (file.type.startsWith("image/") && file.size > 1000 * 1024) {
          try {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);
            return URL.createObjectURL(compressedFile);
          } catch (compressionError) {
            console.error("Image compression error:", compressionError);
            return URL.createObjectURL(file);
          }
        } else {
          return URL.createObjectURL(file);
        }
      }),
    );
    setSelectedImage(imagePreviews);
  };

  //이슈 인덱스 설정
  const issuesPerSlide = 3; // 한 번에 표시할 이슈 수

  const [currentSlide, setCurrentSlide] = useState(0);

  const startIssueIndex = currentSlide * issuesPerSlide;
  const endIssueIndex = startIssueIndex + issuesPerSlide;

  const isFirstSlide = currentSlide === 0; // 슬라이드 첫페이지
  const isLastSlide = endIssueIndex >= breakIssue.length; // 슬라이드 마지막

  return (
    <SideBar.Pushable>
      {/** 사이드바 */}
      <SideBar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible
      >
        <Menu.Item
          as="a"
          style={{
            backgroundColor: selectedIndex === 0 ? "#000000" : "#1b1c1d",
          }}
          onClick={() => {
            setSelectedIndex(0);
            setcurrentpage(1); //사이드메뉴마다 currentpage들  다른화면 갔다오면 1페이지로 초기화되게
            setEventPage(1);
            setFaccurrentpage(1);
          }}
        >
          <Home
            opacity={selectedIndex === 0 ? "1.0" : "0.5"}
            fill={selectedIndex === 0 ? "#00CC66" : "#8c8c8e"}
          />
          <span
            style={{
              color: selectedIndex === 0 ? "#00CC66" : "#8c8c8e",
            }}
          >
            메인
          </span>
        </Menu.Item>
        {inputId &&
          (loginUserRole === "ADMIN" ||
            loginUserRole === "MAINTAINER" ||
            loginUserRole === "INSTALLER") && (
            <Menu.Item
              as="a"
              style={{
                backgroundColor: selectedIndex === 5 ? "#000000" : "#1b1c1d",
              }}
              onClick={() => {
                setSelectedIndex(5);
                setEventPage(1);
                setAsDetail(false);
                setBreakList(false);
                setAsIndexItemsPerPage(10);
              }}
            >
              <Clipboard 
                 opacity={selectedIndex === 5 ? "1.0" : "0.5"} 
                 fill={selectedIndex === 5 ? "#00CC66" : "#8c8c8e"} 
               /> 
              <span
                style={{
                  color: selectedIndex === 5 ? "#00CC66" : "#8c8c8e",
                }}
              >
                이벤트현황
              </span>
            </Menu.Item>
          )}
        {inputId && (
          <Menu.Item
            as="a"
            style={{
              backgroundColor: selectedIndex === 1 ? "#000000" : "#1b1c1d",
            }}
            onClick={() => {
              setSelectedIndex(1);
            }}
          >
            <History
              opacity={selectedIndex === 1 ? "1.0" : "0.5"}
              fill={selectedIndex === 1 ? "#00CC66" : "#8c8c8e"}
            />
            <span
              style={{
                color: selectedIndex === 1 ? "#00CC66" : "#8c8c8e",
              }}
            >
              A/S이력
            </span>
          </Menu.Item>
        )}
        {inputId && (
          <Menu.Item
            as="a"
            style={{
              backgroundColor: selectedIndex === 2 ? "#000000" : "#1b1c1d",
            }}
            onClick={() => {
              setSelectedIndex(2);
              setIndex(0);
              setcurrentpage(1);
              setEventPage(1);
              setFaccurrentpage(1);
            }}
          >
            <Search
              opacity={selectedIndex === 2 ? "1.0" : "0.5"}
              fill={selectedIndex === 2 ? "#00CC66" : "#8c8c8e"}
            />
            <span
              style={{
                color: selectedIndex === 2 ? "#00CC66" : "#8c8c8e",
              }}
            >
              설비 검색
            </span>
          </Menu.Item>
        )}
        {inputId === "" && (
          <Menu.Item
            as="a"
            style={{
              backgroundColor: selectedIndex === 3 ? "#000000" : "#1b1c1d",
            }}
            onClick={() => {
              setSelectedIndex(3);
              setIndex(0);
              setcurrentpage(1);
              setEventPage(1);
              setFaccurrentpage(1);
            }}
          >
            <Search
              opacity={selectedIndex === 3 ? "1.0" : "0.5"}
              fill={selectedIndex === 3 ? "#00CC66" : "#8c8c8e"}
            />
            <span
              style={{
                color: selectedIndex === 3 ? "#00CC66" : "#8c8c8e",
              }}
            >
              로그인
            </span>
          </Menu.Item>
        )}
        {inputId !== "" && (
          <Menu.Item
            as="a"
            style={{
              marginTop: 400,
              backgroundColor: selectedIndex === 4 ? "#000000" : "#1b1c1d",
            }}
            onClick={() => {
              setSelectedIndex(0);
              setcurrentpage(1);
              setEventPage(1);
              setFaccurrentpage(1);
              handleLogout();
            }}
          >
            <Logout opacity="1.0" fill="white" />
            <span
              style={{
                color: "white",
              }}
            >
              로그아웃
            </span>
          </Menu.Item>
        )}
      </SideBar>

      <SideBar.Pusher>
        <Segment basic className="main-header-container">
          <Header as="span" className="main-header">
            경상북도
          </Header>
          <Header as="div" className="main-header-2">
            <div>신재생에너지 통합모니터링 시스템</div>
            <div className="subtitle">Renewable Energy Monitoring System</div>
          </Header>
          {selectedIndex !== 3 && (
            <Header
              as="span"
              className="time"
              style={{
                fontSize: getOS() === "Mac" ? 20 : 18,
              }}
            >
              {inputId && `${inputId}님`}
              <br />
              {time}
            </Header>
          )}
        </Segment>
        {selectedIndex === 0 && (
          <>
            {/* 운영 현황 */}
            <Segment
              as="div"
              basic
              className="container-1"
              style={{ marginRight: "30px" }}
            >
              <Grid className="content-head">
                <Grid.Row className="content-1-title-container">
                  <Grid.Column className="content-1-title" as="div">
                    운영 현황
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="content-1-content-container">
                  <Grid className="content-1-content">
                    <Grid.Row columns={2}>
                      <Grid.Column
                        verticalAlign="center"
                        style={{
                          color: token && viewSlight ? "#00cc66" : "#a4a7ac",
                          fontSize: "20px",
                          fontWeight: 700,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setViewSlight(true);
                          setViewSheat(false);
                          setcurrentpage(1);
                        }}
                      >
                        태양광
                      </Grid.Column>
                      <Grid.Column
                        verticalAlign="center"
                        style={{
                          color: token && viewSheat ? "#00cc66" : "#a4a7ac",
                          fontSize: "20px",
                          fontWeight: 700,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setViewSheat(true);
                          setViewSlight(false);
                          setcurrentpage(1);
                        }}
                      >
                        태양열
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                      <Grid.Column verticalAlign="center">
                        <Grid.Row>
                          <span className="subtitle">
                            {viewSlight ? "금일 발전량" : "금일 생산량"}
                          </span>
                        </Grid.Row>
                        <Grid.Row className="gap">
                          <Power className="image" />
                        </Grid.Row>
                        <Grid.Row>
                          <span className="number">
                            {inputId !== "" && viewSlight
                              ? isNaN(todaySLightAccumulatedValue)
                                ? todaySLightAccumulatedValue
                                : "0.0"
                              : viewSheat
                                ? isNaN(todaySHeatAccumulatedValue)
                                  ? "0.0"
                                  : todaySHeatAccumulatedValue
                                : 0}
                          </span>
                          <span className="unit"> KWh</span>
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column verticalAlign="center">
                        <Grid.Row>
                          <span className="subtitle">누적 발전량</span>
                        </Grid.Row>
                        <Grid.Row className="gap">
                          <Battery className="image" />
                        </Grid.Row>
                        <Grid.Row>
                          <span className="number">
                            {inputId !== "" && viewSlight
                              ? (accumulatedSlight / 1000000).toLocaleString(
                                  "ko-KR",
                                  {
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                  },
                                )
                              : viewSheat
                                ? (accumulatedSheat / 1000000).toLocaleString(
                                    "ko-KR",
                                    {
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                    },
                                  )
                                : 0}
                          </span>
                          <span className="unit"> MWh</span>
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column verticalAlign="center">
                        <Grid.Row>
                          <span className="subtitle">총 설비용량</span>
                        </Grid.Row>
                        <Grid.Row className="gap">
                          <Capacity className="image" />
                        </Grid.Row>
                        {viewSlight && (
                          <Grid.Row>
                            <span className="number">
                              {justTotalData.length > 0 &&
                              justTotalData[0].solarLight
                                ? justTotalData[0].solarLight.capacity.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )
                                : 0}
                            </span>
                            <span className="unit"> kW</span>
                          </Grid.Row>
                        )}

                        {viewSheat && (
                          <Grid.Row>
                            <span className="number">
                              {justTotalData.length > 0 &&
                              justTotalData[0].solarHeat
                                ? justTotalData[0].solarHeat.capacity.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    },
                                  )
                                : 0}
                            </span>
                            <span className="unit"> ㎡</span>
                          </Grid.Row>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={4}>
                      <Grid.Column verticalAlign="center">
                        <Grid.Row as="div" className="circle-1">
                          <div
                            style={{
                              fontSize: 23.5,
                            }}
                          >
                            {viewSlight ? solarLight.length : solarHeat.length}
                          </div>
                          <div
                            style={{
                              fontSize: 13.5,
                            }}
                          >
                            총 개소
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column verticalAlign="center">
                        <Grid.Row as="div" className="circle-2">
                          <div
                            style={{
                              fontSize: 23.5,
                            }}
                          >
                            {filterNice.length}
                          </div>
                          <div
                            style={{
                              fontSize: 13.5,
                            }}
                          >
                            정상
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column verticalAlign="center">
                        <Grid.Row as="div" className="circle-4">
                          <div
                            style={{
                              fontSize: 23.5,
                            }}
                          >
                            {filterReady.length}
                          </div>
                          <div
                            style={{
                              fontSize: 13.5,
                            }}
                          >
                            중지
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column verticalAlign="center">
                        <Grid.Row as="div" className="circle-3">
                          <div
                            style={{
                              fontSize: 23.5,
                            }}
                          >
                            {filterBad.length}
                          </div>
                          <div
                            style={{
                              fontSize: 13.5,
                            }}
                          >
                            고장
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment as="div" basic className="container-2">
              <Grid className="content-head">
                <Grid.Row className="content-2-title-container">
                  <Grid.Column as="div" className="content-2-title">
                    기상정보
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className="content-2-content-container">
                  <Grid.Column verticalAlign="left">
                    <Grid.Row>
                      <Cloud style={{ marginTop: "-20px" }} />
                    </Grid.Row>
                    <Grid.Row className="content-2">
                      <div>
                        <span className="white-color">일출 : </span>
                        <span className="green-color">
                          {isWLoaded
                            ? new Date(
                                weatherData.sunrise * 1000,
                              ).toLocaleTimeString()
                            : ""}
                        </span>
                      </div>
                    </Grid.Row>
                    <Grid.Row className="content-2">
                      <div>
                        <span className="white-color">일몰 : </span>
                        <span className="green-color">
                          {isWLoaded
                            ? new Date(
                                weatherData.sunset * 1000,
                              ).toLocaleTimeString()
                            : ""}
                        </span>
                      </div>
                    </Grid.Row>
                    <Grid.Row className="content-2">
                      <div>
                        <span className="white-color">날씨 : </span>
                        <span
                          className="green-color"
                          style={{ fontSize: "17px" }}
                        >
                          {isWLoaded ? weatherData.weather : ""}
                        </span>
                      </div>
                      <div />
                    </Grid.Row>
                    <Grid.Row className="content-2">
                      <div>
                        <span className="white-color">온도 : </span>
                        <span className="green-color">
                          {isWLoaded ? `${weatherData.temperature} °C` : ""}
                        </span>
                      </div>
                      <div />
                    </Grid.Row>
                    <Grid.Row className="content-2">
                      <div>
                        <span className="white-color">바람 : </span>
                        <span className="green-color">
                          {isWLoaded ? `${weatherData.wind} m/s` : ""}
                        </span>
                      </div>
                      <div />
                    </Grid.Row>
                    <Grid.Row className="content-2">
                      <div>
                        <span className="white-color">습도 : </span>
                        <span className="green-color">
                          {isWLoaded ? `${weatherData.humidity} %` : ""}
                        </span>
                      </div>
                      <div />
                    </Grid.Row>
                    <Grid.Row className="content-2">
                      <div>
                        <span className="white-color">강수량 : </span>
                        <span className="green-color">
                          {isWLoaded ? ` ${weatherData.precipitation} mm ` : ""}
                        </span>
                      </div>
                      <div />
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            {/* 우측 전체 세그먼트  */}
            <Segment
              className="container-3 rightSide"
              style={{ height: "843px" }}
            >
              <Grid.Row
                className="content-2-title-container"
                // style={{ marginBottom: "10px" }}
              >
                <Grid.Column className="content-2-title">
                  지역별 운영 현황
                </Grid.Column>
              </Grid.Row>
              {/*               <Grid */}
              {/*                 className="content-head regionalContainer" */}
              {/*                 style={{ height: "842px" }} */}
              {/*               > */}
              {/*                 <Grid.Row */}
              {/*                   className="content-2-title-container regionalHead" */}
              {/*                   style={{ borderRadius: "12px 12px 12px 12px" }} */}
              {/*                 > */}
              {/*                   <Grid.Column */}
              {/*                     as="div" */}
              {/*                     className="content-4-title" */}
              {/*                     style={{ */}
              {/*                       marginTop: "5px", */}
              {/*                       fontSize: "25px", */}
              {/*                     }} */}
              {/*                   > */}
              {/*                     지역별 운영 현황 */}
              {/*                   </Grid.Column> */}
              {/*                 </Grid.Row> */}
              {/*                 {/* Main content from here */}
              {/*                 <Grid.Row */}
              {/*                   className="content-2-title-container regionalMain" */}
              {/*                   style={{ */}
              {/*                     display: "flex", */}
              {/*                     justifyContent: "center", */}
              {/*                     alignItems: "center", */}
              {/*                     height: "430px", */}
              {/*                     backgroundColor: "#232428", */}
              {/*                   }} */}
              {/*                 > */}
              {/*                   {/* TODO: Region name : Generate */}
              {/*                   <div */}
              {/*                     style={{ */}
              {/*                       display: "grid", */}
              {/*                       gridTemplateColumns: "repeat(11, 1fr)", */}
              {/*                       gap: "35px", */}
              {/*                       rowGap: "80px", */}
              {/*                     }} */}
              {/*                   > */}
              {/*                     {Object.entries(extractSingleRegionData(allData)).map( */}
              {/*                       ([region, data]) => { */}
              {/*                         console.log("data", data); */}
              {/*                         const ratio = statusColorPrinter(data); */}
              {/*                         console.log("ratio", ratio); */}
              {/*                         return ( */}
              {/*                           <div */}
              {/*                             style={{ */}
              {/*                               position: "relative", */}
              {/*                               width: "121px", */}
              {/*                               height: "121px", */}
              {/*                               borderRadius: "20%", */}
              {/*                             }} */}
              {/*                           > */}
              {/*                             <div */}
              {/*                               style={{ */}
              {/*                                 position: "absolute", */}
              {/*                                 top: 0, */}
              {/*                                 left: 0, */}
              {/*                                 right: 0, */}
              {/*                                 bottom: 0, */}
              {/*                                 border: "10px solid transparent", // 투명 테두리 */}
              {/*                                 borderImage: `conic-gradient( */}
              {/* green 0% ${ratio.normalPercentage}%,  */}
              {/* orange ${ratio.normalPercentage}% ${ratio.normalPercentage + ratio.warningPercentage}%,  */}
              {/* red ${ratio.normalPercentage + ratio.warningPercentage}% 100% */}
              {/* ) 1`, */}
              {/*                                 borderRadius: "20%", */}
              {/*                               }} */}
              {/*                             /> */}
              {/*                             <div */}
              {/*                               style={{ */}
              {/*                                 position: "absolute", */}
              {/*                                 top: "10px", */}
              {/*                                 left: "10px", */}
              {/*                                 right: "10px", */}
              {/*                                 bottom: "10px", */}
              {/*                                 backgroundColor: "#232428", */}
              {/*                                 borderRadius: "20%", */}
              {/*                                 display: "flex", */}
              {/*                                 justifyContent: "center", */}
              {/*                                 alignItems: "center", */}
              {/*                                 flexDirection: "column", */}
              {/*                                 color: "white", */}
              {/*                               }} */}
              {/*                             > */}
              {/*                               <div style={{ fontSize: 21.5 }}>{region}</div> */}
              {/*                               <div style={{ fontSize: 20.5 }}>{data.total}</div> */}
              {/*                             </div> */}
              {/*                           </div> */}
              {/*                         ); */}
              {/*                       }, */}
              {/*                     )} */}
              {/**/}
              {/*                     {/* <Grid.Row */}
              {/*                     {/*   as="div" */}
              {/*                     {/*   className="rectangle-1" */}
              {/*                     {/*   key={region} */}
              {/*                     {/*   style={{ */}
              {/*                     {/*     background: `conic-gradient(green ${ratio.normalPercentage}%, orange ${ratio.warningPercentage}%, red ${ratio.faultPercentage}%)`, */}{" "}
              {/*                     {/*     border: "1px solid white", */}
              {/*                     {/*   }} */}
              {/*                     {/* > */}
              {/*                     {/*   <div */}
              {/*                     {/*     style={{ */}
              {/*                     {/*       fontSize: 21.5, */}
              {/*                     {/*       textAlign: "center", */}
              {/*                     {/*       zIndex: 1, */}
              {/*                     {/*     }} */}
              {/*                     {/*   > */}
              {/*                     {/*     {region} */}
              {/*                     {/*   </div> */}
              {/*                     {/*   <div */}
              {/*                     {/*     style={{ */}
              {/*                     {/*       fontSize: 20.5, */}
              {/*                     {/*       textAlign: "center", */}
              {/*                     {/*       zIndex: 1, */}
              {/*                     {/*     }} */}
              {/*                     {/*   > */}
              {/*                     {/*     {data.total} */}
              {/*                     {/*   </div> */}
              {/*                     {/* </Grid.Row> */}
              {/*                     {/*     ); */}
              {/*                     {/*   }, */}
              {/*                     {/* )} */}
              {/*                   </div> */}
              {/*                 </Grid.Row> */}
              {/*               </Grid> */}
              <SimpleGeo mapData={extractSingleRegionData(allData)} />
            </Segment>
            {/* 좌하단 세그먼트  */}
            <Segment
              as="div"
              basic
              className="content-4-container leftBottom"
              // style={{ width: "868px" }}
              // style={{ marginTop: "30px" }}
            >
              <Grid
                className="recentDays"
                style={{
                  width: "868px",
                }}
              >
                <Grid.Row className="content-2-title-container">
                  <Grid.Column className="content-2-title">
                    최근 15일 일일 발전량
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  className="chart-container"
                  style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  {/* 차트(chart) 값 설정하는 곳 */}
                  {dailyLoading ? (
                    token !== "" && <Loading />
                  ) : (
                    <Bar
                      data={data}
                      height={104}
                      options={{
                        responsive: true,
                        scales: {
                          yAxes: {
                            grid: {
                              drawBorder: true,
                              color: "grey",
                            },
                            ticks: {
                              callback: function (value) {
                                return viewSlight
                                  ? value.toString() + "K (MWh)"
                                  : value.toString() + " (MWh)";
                              },
                              fontColor: "white",
                            },
                          },
                          x: {
                            display: true,
                          },
                        },
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                const value = context.raw;
                                const date = new Date();
                                date.setDate(
                                  today.getDate() -
                                    (labels.length - 1 - context.dataIndex), //역순으로 계산된 인덱스 얻기 방법
                                );
                                const day = date.getDate();
                                const month = date.getMonth() + 1;
                                return `${
                                  viewSlight
                                    ? `${month}월 ${day}일 : ${value}K (MWh)`
                                    : `${month}월 ${day}일 : ${value} (MWh)`
                                }`;
                              },
                            },
                          },
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  )}
                </Grid.Row>
              </Grid>
            </Segment>
          </>
        )}
        {selectedIndex === 1 && inputId !== "" && (
          <As
            tempNoteAttachmentList={tempNoteAttachmentList}
            imageUrls={imageUrls}
            openImagePreview={openImagePreview}
            selectedIndex={selectedIndex}
            // fetchDataCallback={fetchDataCallback}
            fetchIssueCallback={fetchIssueCallback}
            eventMainData={eventMainData}
            time={time}
            inputId={inputId}
            issueMainData={issueData}
            eventCurrentData={eventCurrentData}
            loginUserRole={loginUserRole}
            loginUserName={loginUserName}
            loginUserTitle={loginUserTitle}
            handleSearchNote={handleSearchNote}
            noteDataCallback={noteDataCallback}
            handlePreview={handlePreview}
            token={token}
            mainData={eventMainData}
            handleBreakListCall={handleBreakListCall}
            clickedDiv={clickedDiv}
            setClickDiv={setClickDiv}
            getNotesWithIssueId={getNotesWithIssueId}
            setSelectNoteId={setSelectNoteId}
            selectNoteId={selectNoteId}
            loading={loading}
          />
        )}
        {selectedIndex === 5 && inputId !== "" && (
          <>
            <Segment basic className="fac-basic-segment">
              <Grid>
                <Grid.Row className="padding-zero" columns={8}>
                  <Grid.Column className="as-history-title">
                    이벤트 현황
                    <button
                      onClick={handleEventRefresh}
                      className="eventButton"
                    >
                      <i className="redo alternate icon"></i>
                    </button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="padding-zero">
                  <Grid.Column className="content-section">
                    <Grid.Row>
                      <Input
                        icon="search"
                        placeholder="검색어를 입력해주세요"
                        value={searchEventData}
                        onChange={(e) => {
                          setSearchEventData(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setEventSearchOn((prev) => !prev);
                          }
                        }}
                      />
                      <Button
                        className="btn-event"
                        onClick={() => setEventSearchOn((prev) => !prev)}
                      >
                        조회하기
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  className="event-table-container"
                  style={{
                    minHeight: breakList || asDetail ? "300px" : "390px",
                  }}
                >
                  <Table celled inverted className="event-table">
                    <Table.Header className="table-header">
                      <Table.Row cells={5}>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "14%" }}
                        >
                          <Dropdown
                            placeholder="에너지원"
                            selection="selection"
                            onChange={handleEventEnergyFilter}
                            value={eventEnergyFilter}
                            upward={false}
                            style={{
                              minWidth: 0,
                              zIndex: 999,
                              backgroundColor: "#2D2D31",
                              color: "rgba(255,255,255,.9)",
                              textAlign: "center",
                              overflow: "visible",
                            }}
                            options={[
                              {
                                text: "에너지원",
                                value: "에너지원",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "태양광(단상)",
                                value: "태양광(단상)",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "태양광(삼상)",
                                value: "태양광(삼상)",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "태양열(자연식)",
                                value: "태양열(자연식)",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "태양열(강제식)",
                                value: "태양열(강제식)",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                            ]}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "22%" }}
                        >
                          사용자
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "15%" }}
                        >
                          설비 업체
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "15%" }}
                        >
                          모니터링 업체
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "28%" }}
                        >
                          <Dropdown
                            placeholder="주소"
                            selection="selection"
                            value={eventAddress}
                            upward={false}
                            options={[
                              {
                                text: "주소",
                                value: "주소",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "포항시",
                                value: "포항시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "구미시",
                                value: "구미시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "경산시",
                                value: "경산시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "경주시",
                                value: "경주시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "안동시",
                                value: "안동시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "김천시",
                                value: "김천시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "칠곡군",
                                value: "칠곡군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "영천시",
                                value: "영천시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "영주시",
                                value: "영주시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "상주시",
                                value: "상주시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "문경시",
                                value: "문경시",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "예천군",
                                value: "예천군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "의성군",
                                value: "의성군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "울진군",
                                value: "울진군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "성주군",
                                value: "성주군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "청도군",
                                value: "청도군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "영덕군",
                                value: "영덕군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "고령군",
                                value: "고령군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "봉화군",
                                value: "봉화군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "청송군",
                                value: "청송군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "군위군",
                                value: "군위군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "영양군",
                                value: "영양군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "울릉군",
                                value: "울릉군",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                            ]}
                            onChange={handleEventAddress}
                            style={{
                              zIndex: 999,
                              backgroundColor: "#2D2D31",
                              color: "rgba(255,255,255,.9)",
                              textAlign: "center",
                              overflow: "visible",
                            }}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "10%" }}
                        >
                          <Dropdown
                            placeholder="상태"
                            selection="selection"
                            onChange={handleEventStatusFilter}
                            value={eventStatusFilter}
                            upward={false}
                            style={{
                              minWidth: 0,
                              width: "150px",
                              zIndex: 999,
                              backgroundColor: "#2D2D31",
                              color: "rgba(255,255,255,.9)",
                              textAlign: "center",
                              overflow: "visible",
                            }}
                            options={[
                              {
                                text: "상태",
                                value: "상태",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "접수",
                                value: "OPENED",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "진행중",
                                value: "IN_PROGRESS",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "승인요청",
                                value: "WAITING_APPROVAL",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "수리완료",
                                value: "CLOSED",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                              {
                                text: "수리포기",
                                value: "DISCARDED",
                                style: {
                                  border: "none",
                                  color: "white",
                                  backgroundColor: "#323232",
                                  borderColor: "#323232",
                                  textAlign: "center",
                                },
                              },
                            ]}
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {loading ? (
                        <Loading />
                      ) : eventCurrentData.length > 0 ? (
                        eventCurrentData.map((data, i) => {
                          var address = data.addressData;
                          var energyData = data.energy;
                          var ownerTitle = data.owner;
                          var installerTitle = data.installer;
                          var maintainerTitle = data.maintainer;
                          let statusText;

                          const statusData = issueTotal.filter(
                            (item) => item.equipmentId === data.id,
                          );
                          const latestIssue = statusData[statusData.length - 1];

                          if (latestIssue) {
                            statusText = latestIssue.status;
                          } else {
                            statusText = data.status;
                          }

                          const eventHandleClick = () => {
                            if (!latestIssue) {
                              setSelectEquipmentId(data.id);
                              const userConfirmed = window.confirm(
                                "수리 내역이 없습니다. 작성하시겠습니까?",
                              );
                              if (userConfirmed) {
                                setWriteIssue(true);
                              }
                            } else {
                              setSelectIssueId(data.id);
                              setClickDiv(null);
                              setAddFormMode(false);
                              setSelectEquipmentId(data.id);
                              handleBreakListCall(data.id);
                              setCurrentSlide(0);
                              handleSearchAs(data.id);
                            }
                          };

                          return (
                            <Table.Row key={i}>
                              <Table.Cell
                                textAlign="center"
                                onClick={eventHandleClick}
                                style={{ cursor: "pointer", height: "58px" }}
                              >
                                {energyData}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                onClick={eventHandleClick}
                                style={{ cursor: "pointer", height: "58px" }}
                              >
                                {ownerTitle}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                onClick={eventHandleClick}
                                style={{ cursor: "pointer", height: "58px" }}
                              >
                                {installerTitle}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                onClick={eventHandleClick}
                                style={{ cursor: "pointer", height: "58px" }}
                              >
                                {maintainerTitle}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                onClick={eventHandleClick}
                                style={{ cursor: "pointer", height: "58px" }}
                              >
                                {address}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <button
                                  className={`as-button ${
                                    latestIssue ? "" : "red-text"
                                  }`}
                                  onClick={eventHandleClick}
                                >
                                  {latestIssue
                                    ? options.find(
                                        (item) => item.key === statusText,
                                      )?.text
                                    : data.status}
                                </button>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Empty selectedIndex={selectedIndex} />
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Row>
              </Grid>
              <Grid.Row className="event-pagination">
                <Table.Footer>
                  {/* 이벤트 페이지네이션 */}
                  {eventCurrentData.length > 0 ? (
                    <Pagination
                      activePage={eventPage}
                      totalPages={eventTotalPages}
                      onPageChange={handleEventChange}
                    />
                  ) : (
                    ""
                  )}
                </Table.Footer>
              </Grid.Row>
            </Segment>

            {/* breakList 나올 곳 */}
            {breakList && (
              <Segment basic className="basic-segment2-break">
                <>
                  <div className="break">
                    고장 목록
                    <span
                      style={{ height: "50px" }}
                      onClick={(e) => {
                        setSelectIssueId(selectIssueId);
                        setSelectEquipmentId(selectEquipmentId);
                        setWriteIssue(true);
                      }}
                    >
                      <PlusBtn2
                        style={{
                          cursor: "pointer",
                          marginLeft: "8px",
                        }}
                      />
                    </span>
                  </div>
                  <div />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LeftArrowBtn
                      style={{
                        marginLeft: "7px",
                        marginRight: "26px",
                        cursor: isFirstSlide ? "not-allowed" : "pointer",
                        opacity: isFirstSlide ? 0.2 : 1,
                      }}
                      onClick={() => {
                        if (!isFirstSlide) {
                          setCurrentSlide(currentSlide - 1);
                        }
                      }}
                    />
                    {breakIssue
                      .slice(startIssueIndex, endIssueIndex)
                      .map((data, i) => {
                        return (
                          <Grid.Column
                            className={`content-section-inside2 ${
                              i !== 0 && "margin"
                            }`}
                            key={data.id}
                          >
                            <div
                              key={i}
                              style={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "12px",
                                  fontSize: "20px",
                                }}
                              >
                                {data.notes.length > 0
                                  ? data.notes[0].postedAt
                                      .split("T")[0]
                                      .split("-")
                                      .join(".")
                                  : ""}
                              </div>
                              {data.notes.length > 0 && (
                                <RightArrow
                                  style={{
                                    marginRight: "300px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    getNotesWithIssueId(data.id);
                                    setSelectIssueId(data.id);
                                    setSelectEquipmentId(data.equipmentId);
                                    setAddFormMode(true);
                                    setBreakList(false);
                                    setAsDetail(true);
                                    setClickDiv(null);
                                  }}
                                />
                              )}
                              <X
                                style={{
                                  cursor: "pointer",
                                  marginLeft:
                                    data.notes.length < 1 ? "410px" : "0",
                                }}
                                onClick={(e) => {
                                  deleteIssue(data.id);
                                }}
                              />
                            </div>

                            <div />
                            <Grid className="as progress wrapper" as="div">
                              {breakIssue.length > 0 &&
                                data.notes.map((item, index) => {
                                  return (
                                    <Grid.Row
                                      key={index}
                                      className="padding-zero"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: "3px",
                                      }}
                                    >
                                      <Grid.Column
                                        style={{
                                          marginRight: 10,
                                        }}
                                      >
                                        <Complitive
                                          style={{
                                            transform: "translateY(21px)",
                                          }}
                                        />
                                      </Grid.Column>
                                      {data.notes.length > 0 ? (
                                        <Grid.Column
                                          onClick={(e) => {
                                            setClickDiv(null);
                                            getNotesWithIssueId(data.id);
                                            setSelectIssueId(data.id);
                                            setSelectEquipmentId(
                                              data.equipmentId,
                                            );
                                            setAddFormMode(true);
                                            setBreakList(false);
                                            setAsDetail(true);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div
                                            className={
                                              index === data.notes.length - 1
                                                ? "as progress contentbox isprogressing"
                                                : "as progress contentbox aligntop"
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <span>
                                                {options.find(
                                                  (data) =>
                                                    data.key === item.status,
                                                )?.text || ""}
                                              </span>
                                              <span
                                                style={{
                                                  fontSize: "14px !important",
                                                }}
                                              >{`담당자: ${
                                                item.writer.name || ""
                                              } (${
                                                item.writer.contact || ""
                                              })`}</span>
                                            </div>
                                            {index === data.notes.length - 1
                                              ? item.postedAt &&
                                                item.postedAt
                                                  .split("T")[0]
                                                  .split("-")
                                                  .join(".") + "~"
                                              : item.postedAt &&
                                                item.postedAt
                                                  .split("T")[0]
                                                  .split("-")
                                                  .join(".")}
                                          </div>
                                        </Grid.Column>
                                      ) : (
                                        ""
                                      )}
                                    </Grid.Row>
                                  );
                                })}
                              <Grid.Row
                                className="padding-zero"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Grid.Column
                                  style={{
                                    marginRight: 10,
                                  }}
                                />
                                <Grid.Column>
                                  {
                                    <div className="as progress contentbox empty">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        {/* 고장목록에서 항목추가 */}
                                        <span>
                                          <PlusBtn />
                                        </span>
                                        <span
                                          onClick={(e) => {
                                            setClickDiv(null);
                                            getNotesWithIssueId(data.id);
                                            setSelectIssueId(data.id);
                                            setSelectEquipmentId(
                                              data.equipmentId,
                                            );
                                            setAddFormMode(true);
                                            setBreakList(false);
                                            setAsDetail(true);
                                          }}
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          항목 추가
                                        </span>
                                      </div>
                                    </div>
                                  }
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                        );
                      })}
                    <RightArrowBtn
                      style={{
                        marginLeft: "26px",
                        marginRight: "-12px",
                        cursor: isLastSlide ? "not-allowed" : "pointer",
                        opacity: isLastSlide ? 0.2 : 1,
                      }}
                      onClick={(e) => {
                        if (!isLastSlide) {
                          setCurrentSlide(currentSlide + 1);
                        }
                      }}
                    />
                  </div>
                </>
              </Segment>
            )}

            {/* asDetail 나올 곳*/}
            {asDetail &&
              breakIssue.map((data, i) => {
                return (
                  <Segment basic className="basic-segment2" key={i}>
                    <Grid>
                      <Grid.Row className="padding-zero">
                        <Grid.Row
                          className={`content-section2 ${
                            checkBroken && "checkBroken"
                          }`}
                        >
                          {!checkBroken && (
                            <>
                              <Grid.Column className="content-section-inside">
                                <div
                                  style={{
                                    fontSize: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className={`as is ${
                                      rejected ? "rejected" : "processing"
                                    }`}
                                  >
                                    {!rejected && "A/S 진행중"}
                                    {rejected && "반려"}
                                  </div>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleBreakListCall(selectEquipmentId);
                                      setTempNoteList([]);
                                      setClickDiv(null);
                                      setSelectNoteId("");
                                      setBreakList(true);
                                      setAsDetail(false);
                                    }}
                                  >
                                    <i
                                      class="arrow left icon"
                                      style={{ fontSize: "25px" }}
                                    />
                                  </span>
                                </div>
                                <div className="as title">{selectedAsName}</div>
                                <div className="as sub title1">
                                  {clickedDiv !== null && noteData
                                    ? noteData.writer.name
                                    : ""}
                                </div>
                                <div className="as sub title2">
                                  {clickedDiv !== null && noteData
                                    ? noteData.writer.group
                                    : ""}
                                </div>
                                <div className="as divider" />
                                <div
                                  className="as progress title"
                                  style={{ marginBottom: 15 }}
                                >
                                  진행상황
                                </div>
                                <Grid className="as progress wrapper" as="div">
                                  {data.notes ? (
                                    <>
                                      {tempNoteList.length > 0
                                        ? tempNoteList.map((note, index) => {
                                            return (
                                              <Grid.Row
                                                className="padding-zero"
                                                key={index}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  marginBottom: "3px",
                                                }}
                                              >
                                                <Grid.Column
                                                  style={{ marginRight: 10 }}
                                                >
                                                  {index === clickedDiv ? (
                                                    <Progressive
                                                      style={{
                                                        transform:
                                                          "translateY(21px)",
                                                      }}
                                                    />
                                                  ) : (
                                                    <Complitive
                                                      style={{
                                                        transform:
                                                          "translateY(21px)",
                                                      }}
                                                    />
                                                  )}
                                                </Grid.Column>
                                                <Grid.Column>
                                                  <div
                                                    className={
                                                      index === clickedDiv
                                                        ? "as progress contentbox isprogressing"
                                                        : "as progress contentbox aligntop"
                                                    }
                                                    style={{
                                                      justifyContent: "top",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setSelectNoteId(
                                                            note.id,
                                                          );
                                                          handleSearchNote(
                                                            note.id,
                                                          );
                                                          setClickDiv(index);
                                                          setAddFormMode(false);
                                                        }}
                                                      >
                                                        {tempNoteList.length > 0
                                                          ? options.find(
                                                              (item) =>
                                                                item.key ===
                                                                note.status,
                                                            )?.text
                                                          : ""}
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize:
                                                            "14px !important",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setSelectNoteId(
                                                            note.id,
                                                          );
                                                          handleSearchNote(
                                                            note.id,
                                                          );
                                                          setClickDiv(index);
                                                          setAddFormMode(false);
                                                        }}
                                                      >{`담당자: ${
                                                        note.writer.name || ""
                                                      } (${
                                                        note.writer.contact ||
                                                        ""
                                                      })`}</span>
                                                    </div>
                                                    <span>
                                                      {index ===
                                                      tempNoteList.length - 1
                                                        ? note.postedAt &&
                                                          note.postedAt
                                                            .split("T")[0]
                                                            .split("-")
                                                            .join(".") + "~"
                                                        : note.postedAt &&
                                                          note.postedAt
                                                            .split("T")[0]
                                                            .split("-")
                                                            .join(".")}
                                                    </span>
                                                  </div>
                                                </Grid.Column>
                                              </Grid.Row>
                                            );
                                          })
                                        : ""}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {rejected && (
                                    <Grid.Row
                                      className="padding-zero"
                                      style={{
                                        display: "flex",
                                        marginBottom: "3px",
                                      }}
                                    >
                                      <Grid.Column
                                        style={{
                                          marginRight: 10,
                                        }}
                                      >
                                        <RejectedIcon
                                          style={{
                                            transform: "translateY(21px)",
                                          }}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <div
                                          className={`as progress contentbox ${
                                            rejected
                                              ? "isrejected"
                                              : "isprogressing"
                                          }`}
                                          style={{
                                            justifyContent: "top",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <span>A/S진행중 - 반려</span>
                                            <span
                                              style={{
                                                fontSize: "14px !imnportant",
                                              }}
                                            >
                                              담당자 : 홍길동 (010-1234-5678)
                                            </span>
                                          </div>
                                          <span>23.05.21 ~</span>
                                        </div>
                                      </Grid.Column>
                                    </Grid.Row>
                                  )}
                                  <Grid.Row
                                    className="padding-zero"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Grid.Column
                                      style={{
                                        marginRight: 10,
                                      }}
                                    />
                                    <Grid.Column>
                                      {
                                        <div className="as progress contentbox empty">
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span style={{ cursor: "pointer" }}>
                                              <PlusBtn />
                                            </span>
                                            <span
                                              onClick={() => {
                                                setAddFormMode(true);
                                                setSelectStatusText("");
                                                setSelectedStatus("");
                                              }}
                                              style={{
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              항목추가
                                            </span>
                                          </div>
                                        </div>
                                      }
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column className="content-section-inside withcustompadding">
                                <Grid.Row
                                  className="padding-zero"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <Grid.Column className="padding-zero third">
                                    <div>수리상태</div>
                                    {!addFormMode && (
                                      <div
                                        style={{
                                          fontWeight: "700",
                                          color: "#00CC66",
                                          fontSize: "25px",
                                        }}
                                      >
                                        {clickedDiv !== null && noteData
                                          ? options.find(
                                              (item) =>
                                                item.key === noteData.status,
                                            )?.text
                                          : ""}
                                      </div>
                                    )}
                                    {addFormMode && (
                                      <Select
                                        placeholder="수리상태 선택"
                                        options={options}
                                        style={{
                                          marginTop: "13px",
                                          marginBottom: "13px",
                                          height: "53px",
                                          width: "480px",
                                          backgroundColor: "#626468",
                                        }}
                                        value={selectedStatus}
                                        onChange={(e) => {
                                          const selectedOption = options.find(
                                            (option) =>
                                              option.text ===
                                              e.currentTarget.textContent,
                                          );
                                          setSelectedStatus(
                                            selectedOption.text,
                                          );
                                          setSelectStatusText(
                                            selectedOption.key,
                                          );
                                        }}
                                      />
                                    )}
                                    <div />
                                    <div>작업자 정보</div>
                                    <div
                                      className={!addFormMode && "aligned"}
                                      style={{ marginTop: "25px" }}
                                    >
                                      <span style={{ width: "63px" }}>
                                        소속{addFormMode && <span>*</span>}
                                      </span>
                                      {!addFormMode && (
                                        <span>
                                          {clickedDiv !== null && noteData
                                            ? noteData.writer.group
                                            : ""}
                                        </span>
                                      )}
                                      {addFormMode && (
                                        <input
                                          type="text"
                                          className={`column-group ${
                                            belong.length === 0 && "empty"
                                          }`}
                                          style={{
                                            backgroundColor: "#393D42",
                                            color: "white",
                                            width: "414px",
                                            outline: "0",
                                            border: "none",
                                            borderBottom: "1px solid gray",
                                          }}
                                          placeholder="소속을 입력해주세요."
                                          value={belong}
                                          onChange={(e) =>
                                            setBelong(e.target.value)
                                          }
                                        />
                                      )}
                                    </div>
                                    <div
                                      className={!addFormMode && "aligned"}
                                      style={{
                                        marginTop: addFormMode && "27px",
                                      }}
                                    >
                                      <span style={{ width: "63px" }}>
                                        이름{addFormMode && <span>*</span>}
                                      </span>
                                      {!addFormMode && (
                                        <span>
                                          {clickedDiv !== null && noteData
                                            ? noteData.writer.name
                                            : ""}
                                        </span>
                                      )}
                                      {addFormMode && (
                                        <input
                                          className={`column-group ${
                                            name.length === 0 && "empty"
                                          }`}
                                          style={{
                                            backgroundColor: "#393D42",
                                            color: "white",
                                            width: "414px",
                                            outline: "0",
                                            border: "none",
                                            borderBottom: "1px solid gray",
                                          }}
                                          placeholder="이름을 입력해주세요."
                                          value={name}
                                          onChange={(e) => {
                                            setName(e.target.value);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div
                                      className={!addFormMode && "aligned"}
                                      style={{
                                        marginTop: addFormMode && "27px",
                                      }}
                                    >
                                      <span style={{ width: "63px" }}>
                                        직급{addFormMode && <span>*</span>}
                                      </span>
                                      {!addFormMode && (
                                        <span>
                                          {clickedDiv !== null && noteData
                                            ? noteData.writer.title
                                            : ""}
                                        </span>
                                      )}
                                      {addFormMode && (
                                        <input
                                          className={`column-group ${
                                            rank.length === 0 && "empty"
                                          }`}
                                          style={{
                                            backgroundColor: "#393D42",
                                            color: "white",
                                            width: "414px",
                                            outline: "0",
                                            border: "none",
                                            borderBottom: "1px solid gray",
                                          }}
                                          value={rank}
                                          placeholder="직급을 입력해주세요."
                                          onChange={(e) => {
                                            setRank(e.target.value);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div
                                      className={!addFormMode && "aligned"}
                                      style={
                                        addFormMode
                                          ? {
                                              marginTop: "21px",
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                            }
                                          : {}
                                      }
                                    >
                                      <span style={{ width: "74px" }}>
                                        연락처{addFormMode && <span>*</span>}
                                      </span>
                                      {!addFormMode && (
                                        <span>
                                          {clickedDiv !== null && noteData
                                            ? noteData.writer.contact
                                            : ""}
                                        </span>
                                      )}
                                      {addFormMode && (
                                        <span
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              placeholder="000"
                                              value={firstPhoneNum}
                                              maxLength={3}
                                              onChange={handleFirstPartChange}
                                              style={{
                                                textAlign: "center",
                                                color: "white",
                                                fontSize: "20px",
                                                height: "55px",
                                                maxWidth: "80px",
                                                backgroundColor: "transparent",
                                                border: "1px solid #75777b",
                                                marginLeft: "20px",
                                                marginRight: "4px",
                                              }}
                                            />
                                            <div
                                              style={{
                                                marginLeft: "12px",
                                                color: "#FFF",
                                                fontSize: "16px",
                                                fontFamily: "appler",
                                                fontWeight: "400",
                                              }}
                                            >
                                              -
                                            </div>
                                            <input
                                              type="text"
                                              placeholder="0000"
                                              value={secondPhoneNum}
                                              maxLength={4}
                                              onChange={handleSecondPartChange}
                                              style={{
                                                textAlign: "center",
                                                height: "55px",
                                                fontSize: "20px",
                                                maxWidth: "100px",
                                                color: "white",
                                                backgroundColor: "transparent",
                                                border: "1px solid #75777b",
                                                marginLeft: "20px",
                                                marginRight: "4px",
                                              }}
                                            />
                                            <div
                                              style={{
                                                marginLeft: "12px",
                                                color: "#FFF",
                                                fontSize: "16px",
                                                fontFamily: "appler",
                                                fontWeight: "400",
                                              }}
                                            >
                                              -
                                            </div>
                                            <input
                                              type="text"
                                              placeholder="0000"
                                              value={thirdPhoneNum}
                                              maxLength={4}
                                              onChange={handleThirdPartChange}
                                              style={{
                                                textAlign: "center",
                                                height: "55px",
                                                fontSize: "20px",
                                                maxWidth: "100px",
                                                color: "white",
                                                backgroundColor: "transparent",
                                                border: "1px solid #75777b",
                                                marginLeft: "20px",
                                                marginRight: "4px",
                                              }}
                                            />
                                          </div>
                                        </span>
                                      )}
                                    </div>
                                  </Grid.Column>
                                  <div
                                    style={{
                                      height: "427px",
                                      marginRight: "1px solid #FFF",
                                      opacity: 0.1,
                                    }}
                                  />
                                  <Grid.Column className="padding-zero fourth">
                                    <div>작업 내용</div>
                                    {!addFormMode && (
                                      <div>
                                        <div className="readOnly-content">
                                          {clickedDiv !== null && noteData
                                            ? noteData.text
                                            : ""}
                                        </div>
                                        <div className="fileContent">
                                          {!addFormMode && (
                                            <span
                                              style={{ marginRight: "100px" }}
                                            >
                                              첨부파일
                                            </span>
                                          )}
                                          <div
                                            style={{
                                              maxWidth: "300px",
                                              maxHeight: "120px",
                                              overflowX: "auto",
                                              overflowY: "hidden",
                                            }}
                                          >
                                            <div style={{ display: "flex" }}>
                                              {!addFormMode &&
                                              tempNoteAttachmentList.length >
                                                0 ? (
                                                tempNoteAttachmentList.map(
                                                  (attachment, i) => (
                                                    <div key={attachment.id}>
                                                      <img
                                                        style={{
                                                          width: "50px",
                                                          height: "60px",
                                                          margin: "0 5px 0 5px",
                                                          cursor: "pointer",
                                                        }}
                                                        src={
                                                          imageUrls[
                                                            attachment.id
                                                          ]
                                                        }
                                                        alt={
                                                          attachment.filename
                                                        }
                                                        onClick={() =>
                                                          openImagePreview(
                                                            attachment.id,
                                                          )
                                                        }
                                                      />
                                                      <div
                                                        style={{
                                                          top: "50px",
                                                          textAlign: "center",
                                                          cursor: "pointer",
                                                          color: "red",
                                                          fontSize: "20px",
                                                          opacity: 0.5,
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          e.currentTarget.style.opacity = 1;
                                                        }}
                                                        onMouseLeave={(e) => {
                                                          e.currentTarget.style.opacity = 0.5;
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            width: "100%",
                                                            height: "100%",
                                                            color: "red",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            const confirmDelete =
                                                              window.confirm(
                                                                "삭제하시겠습니까?",
                                                              );
                                                            if (confirmDelete) {
                                                              handleImgDelete(
                                                                attachment.id,
                                                              );
                                                              getNotesWithIssueId(
                                                                selectIssueId,
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          X
                                                        </span>
                                                      </div>
                                                    </div>
                                                  ),
                                                )
                                              ) : (
                                                <span
                                                  style={{ height: "30px" }}
                                                >
                                                  No Image
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {noteData && (
                                          <button
                                            className={
                                              noteData.attachments &&
                                              noteData.attachments.length > 0
                                                ? "update-button"
                                                : "notImg-update-button"
                                            }
                                            onClick={() => {
                                              setWriteNote(!writeNote);
                                            }}
                                          >
                                            <DeleteBtn
                                              style={{
                                                marginRight: "10px",
                                              }}
                                            />
                                            수정하기
                                          </button>
                                        )}
                                        {noteData && (
                                          <button
                                            onClick={() => {
                                              setDeleteCheck(true);
                                            }}
                                            className="deleteOnly"
                                            style={{
                                              marginTop: "-24.5px",
                                              marginLeft: "130px",
                                            }}
                                          >
                                            <DeleteBtn
                                              style={{
                                                marginRight: "10px",
                                              }}
                                            />
                                            단계삭제
                                          </button>
                                        )}
                                      </div>
                                    )}
                                    {addFormMode && (
                                      <div>
                                        <textarea
                                          value={noteContent}
                                          className="write-content"
                                          onChange={(e) => {
                                            setNoteContent(e.target.value);
                                          }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {addFormMode && (
                                            <span className="file">
                                              첨부파일<span>*</span>
                                            </span>
                                          )}
                                          <span style={{ padding: "10px" }}>
                                            <button
                                              className="fileBtn"
                                              onClick={() => {
                                                fileInputRef.current.click();
                                              }}
                                            >
                                              파일첨부
                                            </button>
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {selectedImage && (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                {selectedImage.map(
                                                  (imageUrl, imgIndex) => (
                                                    <div
                                                      key={imgIndex}
                                                      style={{
                                                        position: "relative",
                                                        marginRight: "10px",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      <img
                                                        className="pri-img"
                                                        src={imageUrl}
                                                        alt=""
                                                        style={{
                                                          maxWidth: "300px",
                                                          maxHeight: "300px",
                                                        }}
                                                      />
                                                      <span
                                                        style={{
                                                          position: "absolute",
                                                          bottom: "-10px",
                                                          right: "4px",
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                          width: "20px",
                                                          height: "20px",
                                                          backgroundColor:
                                                            "red",
                                                          color: "#fff",
                                                          borderRadius: "50%",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          handleImageDelete(
                                                            imgIndex,
                                                          )
                                                        }
                                                      >
                                                        X
                                                      </span>
                                                    </div>
                                                  ),
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <button
                                          className={
                                            selectedImage.isEmpty
                                              ? "notImg-write-button"
                                              : "write-button"
                                          }
                                          onClick={() => {
                                            noteRegister(selectIssueId);
                                            getNotesWithIssueId(selectIssueId);
                                          }}
                                        >
                                          <ModifyBtn
                                            style={{ marginRight: "10px" }}
                                          />
                                          작성하기
                                        </button>
                                        <div>
                                          <input
                                            ref={fileInputRef}
                                            type="file"
                                            id="file-upload"
                                            style={{ display: "none" }}
                                            onChange={handleImagePreview}
                                            multiple
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid.Column>
                            </>
                          )}
                        </Grid.Row>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                );
              })}
          </>
        )}
        {/* indexPage 시작 */}
        {selectedIndex === 2 && (
          <Header className="facSelector">
            {buttons.map((button) => (
              <Button
                key={button.index}
                className="fac-btn-green"
                style={{
                  backgroundColor:
                    button.index === index ? "#00CC66" : "#1b1c1d",
                  border: button.index === index ? "" : "1px solid #00CC66",
                  fill: button.index === index ? "#00CC66" : "#8c8c8e",
                  color: button.index === index ? "white" : "#ffffff",
                  fontSize: 14,
                }}
                onClick={() => {
                  handleClick(button.index);
                  setFacIndexItemsPerPage(10);
                  setFacIndexSearchItemsPerPage(10);
                  setSearchEnergyData("");
                  setFilterEnergyData([]);
                }}
              >
                {button.label}
              </Button>
            ))}
          </Header>
        )}
        {selectedIndex === 2 && inputId !== "" && index === 0 && (
          <>
            <Segment basic className="fac-basic-segment">
              <Grid>
                <Grid.Row className="padding-zero" columns={9}>
                  <Grid.Column className="as-history-title">
                    설비 검색
                    <button onClick={handleFacRefresh} className="eventButton">
                      <i className="redo alternate icon"></i>
                    </button>
                  </Grid.Column>
                  <Grid.Column width={11} />
                </Grid.Row>
                <Grid.Row className="padding-zero">
                  <Grid.Column className="content-section">
                    <Grid.Row>
                      <Input
                        icon="search"
                        placeholder="검색어를 입력해주세요"
                        value={searchFacData}
                        onChange={(e) => {
                          setSearchFacData(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setFacSearchOn((prev) => !prev);
                          }
                        }}
                        className="input"
                      />
                      <Button
                        className="fac-main-btn-green"
                        onClick={() => setFacSearchOn((prev) => !prev)}
                      >
                        조회하기
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="fac-table-container">
                  <Table celled inverted className="table-2">
                    <Table.Header className="table-header-color">
                      <Table.Row>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "11%" }}
                        >
                          에너지원
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "25%" }}
                        >
                          사용자
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "15%" }}
                        >
                          설비업체
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "15%" }}
                        >
                          모니터링업체
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "28%" }}
                        >
                          주소
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          style={{ width: "8%" }}
                        >
                          상태
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {loading
                        ? token !== "" && <Loading />
                        : facAllData.length > 0
                          ? facAllData.map((data) => {
                              const findEnergyData = data.energy;

                              const getStatusText = (status) => {
                                switch (status) {
                                  case "운영준비중":
                                    return "정상";
                                  default:
                                    return status;
                                }
                              };

                              const getStatusColor = (status) => {
                                switch (status) {
                                  case "운영준비중":
                                    return "#00cc66";
                                  case "고장":
                                    return "red";
                                  case "경고":
                                    return "red";
                                  default:
                                    return "#00cc66";
                                }
                              };

                              const findEnergy = () => {
                                let index;
                                switch (findEnergyData) {
                                  case "태양광(단상)":
                                  case "태양광(삼상)":
                                    index = 1;
                                    break;
                                  case "태양열(자연식)":
                                    index = 2;
                                    break;
                                  case "태양열(강제식)":
                                    index = 3;
                                    break;
                                  case "지열":
                                    index = 4;
                                    break;
                                  case "풍력":
                                    index = 5;
                                    break;
                                  case "연료전지":
                                    index = 6;
                                    break;
                                  case "ESS":
                                    index = 7;
                                    break;
                                  default:
                                    index = 0;
                                    break;
                                }
                                setCurrentEquipId(data.id);
                                setIndex(index);
                              };

                              return (
                                <Table.Row key={data.id}>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{
                                      height: "58px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {findEnergyData}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{
                                      height: "58px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.owner}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{
                                      height: "58px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.installer}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{
                                      height: "58px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.maintainer}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{
                                      height: "58px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {data.addressData}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    style={{
                                      color: getStatusColor(data.status),
                                      height: "58px",
                                      cursor: "pointer",
                                    }}
                                    onClick={findEnergy}
                                  >
                                    {getStatusText(data.status)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })
                          : // <Empty className="facEmpty-background" />
                            ""}
                    </Table.Body>
                  </Table>
                </Grid.Row>
              </Grid>
              <Grid.Row className="fac-pagination">
                <Table.Footer>
                  {/* 설비검색 메인 페이지네이션 */}
                  {facAllData.length > 0 ? (
                    <Pagination
                      activePage={faccurrentpage}
                      totalPages={facTotalPages}
                      onPageChange={handlePageChange}
                    />
                  ) : (
                    ""
                  )}
                </Table.Footer>
              </Grid.Row>
            </Segment>
          </>
        )}{" "}
        {/* indexPage 끝 */}
        {/* indexInfo 시작 */}
        {selectedIndex === 2 && index !== 0 && (
          <>
            <Segment basic className="fac-basic-segment">
              <Grid>
                <Grid.Row className="padding-zero" columns={9}>
                  <Grid.Column className="as-history-title">
                    설비 검색
                    <button
                      onClick={handleEnergyRefresh}
                      className="eventButton"
                    >
                      <i className="redo alternate icon"></i>
                    </button>
                  </Grid.Column>
                  <Grid.Column width={11} />
                </Grid.Row>
                <Grid.Row className="padding-zero">
                  <Grid.Column className="content-section">
                    <Grid.Row>
                      <Input
                        icon="search"
                        placeholder="검색어를 입력해주세요"
                        value={searchEnergyData}
                        onChange={(e) => {
                          setSearchEnergyData(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setEnergySearchOn((prev) => !prev);
                          }
                        }}
                        className="input"
                      />
                      <Button
                        className="fac-main-btn-green"
                        onClick={() => setEnergySearchOn((prev) => !prev)}
                      >
                        조회하기
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  className={
                    !energyDetail
                      ? "energy-table-container--expanded"
                      : "energy-table-container"
                  }
                >
                  <Table celled inverted className="energy-table-2">
                    {index === 1 && (
                      <>
                        <Table.Header className="table-header-color">
                          <Table.Row>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "11%" }}
                            >
                              에너지원
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              사용자
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              설비업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              모니터링업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "28%" }}
                            >
                              주소
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "8%" }}
                            >
                              상태
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {loading ? (
                            <Loading />
                          ) : energyCurrentData.length > 0 ? (
                            energyCurrentData.map((data) => {
                              var address = data.addressData;

                              const getStatusText = (status) => {
                                switch (status) {
                                  case "운영준비중":
                                    return "정상";
                                  default:
                                    return status;
                                }
                              };

                              const getStatusColor = (status) => {
                                switch (status) {
                                  case "운영준비중":
                                    return "#00cc66";
                                  case "고장":
                                    return "red";
                                  case "경고":
                                    return "red";
                                  default:
                                    return "#00cc66";
                                }
                              };

                              const findEnergy = () => {
                                if (filterEnergyData.length > 0) {
                                  handleFilterSearchEnergy(data.id);
                                  setfindEnergyId(data.id);
                                }
                                handleSearchEnergy(data.id);
                                setfindEnergyId(data.id);
                              };
                              return (
                                <Table.Row key={data.id}>
                                  <Table.Cell
                                    textAlign="center"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      className="energy-button"
                                      onClick={findEnergy}
                                    >
                                      {data.energy}
                                    </button>
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.owner}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.installer}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.maintainer}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {address}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{
                                      color: getStatusColor(data.status),
                                      cursor: "pointer",
                                    }}
                                  >
                                    {getStatusText(data.status)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })
                          ) : (
                            <Empty />
                          )}
                        </Table.Body>
                        <Grid.Row>
                          <Table.Footer
                            className={
                              !energyDetail
                                ? "energy-pagination--expanded"
                                : "energy-pagination"
                            }
                          >
                            {energyCurrentData.length > 0 ? (
                              <Pagination
                                activePage={energycurrentpage}
                                totalPages={pageCount1}
                                onPageChange={handleEnergyChange}
                              />
                            ) : (
                              ""
                            )}
                          </Table.Footer>
                        </Grid.Row>
                      </>
                    )}

                    {index === 2 && (
                      <>
                        <Table.Header className="table-header-color">
                          <Table.Row cells={7}>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "11%" }}
                            >
                              에너지원
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              사용자
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              설비업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              모니터링업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "28%" }}
                            >
                              주소
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "8%" }}
                            >
                              상태
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {loading ? (
                            <Loading />
                          ) : energyCurrentData.length > 0 ? (
                            energyCurrentData.map((data) => {
                              var address = data.addressData;

                              const getStatusText = (status) => {
                                switch (status) {
                                  case "운영준비중":
                                    return "정상";
                                  default:
                                    return status;
                                }
                              };

                              const getStatusColor = (status) => {
                                switch (status) {
                                  case "운영준비중":
                                    return "#00cc66";
                                  case "고장":
                                    return "red";
                                  case "경고":
                                    return "red";
                                  default:
                                    return "#00cc66";
                                }
                              };

                              const findEnergy = () => {
                                if (filterEnergyData.length > 0) {
                                  handleFilterSearchEnergy(data.id);
                                  setfindEnergyId(data.id);
                                }
                                handleSearchEnergy(data.id);
                                setfindEnergyId(data.id);
                              };
                              return (
                                <Table.Row key={data.id}>
                                  <Table.Cell
                                    textAlign="center"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      className="energy-button"
                                      onClick={findEnergy}
                                    >
                                      {data.energy}
                                    </button>
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.owner}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.installer}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.maintainer}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {address}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{
                                      color: getStatusColor(data.status),
                                      cursor: "pointer",
                                    }}
                                  >
                                    {getStatusText(data.status)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })
                          ) : (
                            <Empty />
                          )}
                        </Table.Body>
                        <Table.Footer
                          className={
                            !energyDetail
                              ? "energy-pagination--expanded"
                              : "energy-pagination"
                          }
                        >
                          {energyCurrentData.length > 0 ? (
                            <Pagination
                              activePage={energycurrentpage}
                              totalPages={pageCount2}
                              onPageChange={handleEnergyChange}
                            />
                          ) : (
                            ""
                          )}
                        </Table.Footer>
                      </>
                    )}

                    {index === 3 && (
                      <>
                        <Table.Header className="table-header-color">
                          <Table.Row cells={7}>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "11%" }}
                            >
                              에너지원
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              사용자
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              설비업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              모니터링업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "28%" }}
                            >
                              주소
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "8%" }}
                            >
                              상태
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {loading ? (
                            <Loading />
                          ) : energyCurrentData.length > 0 ? (
                            energyCurrentData.map((data) => {
                              var address = data.addressData;

                              const getStatusText = (status) => {
                                switch (status) {
                                  case "운영준비중":
                                    return "정상";
                                  default:
                                    return status;
                                }
                              };

                              const getStatusColor = (status) => {
                                switch (status) {
                                  case "운영준비중":
                                    return "#00cc66";
                                  case "고장":
                                    return "red";
                                  case "경고":
                                    return "red";
                                  default:
                                    return "#00cc66";
                                }
                              };

                              const findEnergy = () => {
                                if (filterEnergyData.length > 0) {
                                  handleFilterSearchEnergy(data.id);
                                  setfindEnergyId(data.id);
                                }
                                handleSearchEnergy(data.id);
                                setfindEnergyId(data.id);
                              };

                              return (
                                <Table.Row key={data.id}>
                                  <Table.Cell textAlign="center">
                                    <button
                                      className="energy-button"
                                      onClick={findEnergy}
                                    >
                                      {data.energy}
                                    </button>
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.owner}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.installer}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {data.maintainer}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {address}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    onClick={findEnergy}
                                    style={{
                                      color: getStatusColor(data.status),
                                      cursor: "pointer",
                                    }}
                                  >
                                    {getStatusText(data.status)}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })
                          ) : (
                            <Empty />
                          )}
                        </Table.Body>
                        <Table.Footer
                          className={
                            !energyDetail
                              ? "energy-pagination--expanded"
                              : "energy-pagination"
                          }
                        >
                          {energyCurrentData.length > 0 ? (
                            <Pagination
                              activePage={energycurrentpage}
                              totalPages={pageCount3}
                              onPageChange={handleEnergyChange}
                            />
                          ) : (
                            ""
                          )}
                        </Table.Footer>
                      </>
                    )}

                    {index === 4 && (
                      <>
                        <Table.Header className="table-header-color">
                          <Table.Row cells={7}>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "12%" }}
                            >
                              에너지원
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              사용자
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              설비업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              모니터링업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              주소
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "10%" }}
                            >
                              상태
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {(searchEnergyData === ""
                            ? energyCurrentData
                            : energySearchCurrentData4
                          ).map((data, i) => (
                            <Table.Row key={i}>
                              <Table.Cell textAlign="center">
                                {data.cumConsumePower}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <button
                                  className="energy-button"
                                  onClick={() => handleSearchEnergy(data.id)}
                                >
                                  {data.energy}
                                </button>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.equipmentCompany}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.monitoring}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.cumKcal}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.facCapacity}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                        <Table.Footer
                          className={
                            !energyDetail
                              ? "energy-pagination--expanded"
                              : "energy-pagination"
                          }
                        >
                          {searchEnergyData === "" ? (
                            <Pagination
                              activePage={energycurrentpage}
                              totalPages={pageCount4}
                              onPageChange={handleEnergyChange}
                            />
                          ) : (
                            <Pagination
                              activePage={energySearchPage}
                              totalPages={searchCount4}
                              onPageChange={handleSearchEnergyChange}
                            />
                          )}
                        </Table.Footer>
                      </>
                    )}

                    {index === 5 && (
                      <>
                        <Table.Header className="table-header-color">
                          <Table.Row cells={7}>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "12%" }}
                            >
                              에너지원
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              사용자
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              설비업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              모니터링업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              주소
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "10%" }}
                            >
                              상태
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {(searchEnergyData === ""
                            ? energyCurrentData
                            : energySearchCurrentData5
                          ).map((data, i) => (
                            <Table.Row key={i}>
                              <Table.Cell textAlign="center">
                                {data.cumPower}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <button
                                  className="energy-button"
                                  onClick={() => handleSearchEnergy(data.id)}
                                >
                                  {data.energy}
                                </button>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.equipmentCompany}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.monitoring}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.user}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.facCapacity}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                        <Table.Footer
                          className={
                            !energyDetail
                              ? "energy-pagination--expanded"
                              : "energy-pagination"
                          }
                        >
                          {searchEnergyData === "" ? (
                            <Pagination
                              activePage={energycurrentpage}
                              totalPages={pageCount5}
                              onPageChange={handleEnergyChange}
                            />
                          ) : (
                            <Pagination
                              activePage={energySearchPage}
                              totalPages={searchCount5}
                              onPageChange={handleSearchEnergyChange}
                            />
                          )}
                        </Table.Footer>
                      </>
                    )}
                    {index === 6 && (
                      <>
                        <Table.Header className="table-header-color">
                          <Table.Row cells={7}>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "12%" }}
                            >
                              에너지원
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              사용자
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              설비업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              모니터링업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              주소
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "10%" }}
                            >
                              상태
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {(searchEnergyData === ""
                            ? energyCurrentData
                            : energySearchCurrentData6
                          ).map((data, i) => (
                            <Table.Row key={i}>
                              <Table.Cell textAlign="center">
                                {data.cumPower}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <button
                                  className="energy-button"
                                  onClick={() => handleSearchEnergy(data.id)}
                                >
                                  {data.energy}
                                </button>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.equipmentCompany}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.monitoring}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.user}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.todayPower}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                        <Table.Footer
                          className={
                            !energyDetail
                              ? "energy-pagination--expanded"
                              : "energy-pagination"
                          }
                        >
                          {searchEnergyData === "" ? (
                            <Pagination
                              activePage={energycurrentpage}
                              totalPages={pageCount6}
                              onPageChange={handleEnergyChange}
                            />
                          ) : (
                            <Pagination
                              activePage={energySearchPage}
                              totalPages={searchCount6}
                              onPageChange={handleSearchEnergyChange}
                            />
                          )}
                        </Table.Footer>
                      </>
                    )}

                    {index === 7 && (
                      <>
                        <Table.Header className="table-header-color">
                          <Table.Row cells={7}>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "12%" }}
                            >
                              에너지원
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              사용자
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              설비업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "15%" }}
                            >
                              모니터링업체
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "25%" }}
                            >
                              주소
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              style={{ width: "10%" }}
                            >
                              상태
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {(searchEnergyData === ""
                            ? energyCurrentData
                            : energySearchCurrentData7
                          ).map((data, i) => (
                            <Table.Row key={i}>
                              <Table.Cell textAlign="center">
                                {data.soc}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <button
                                  className="energy-button"
                                  onClick={() => handleSearchEnergy(data.id)}
                                >
                                  {data.energy}
                                </button>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.equipmentCompany}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.monitoring}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.user}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {data.soh}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                        <Table.Footer
                          className={
                            !energyDetail
                              ? "energy-pagination--expanded"
                              : "energy-pagination"
                          }
                        >
                          {searchEnergyData === "" ? (
                            <Pagination
                              activePage={energycurrentpage}
                              totalPages={pageCount7}
                              onPageChange={handleEnergyChange}
                            />
                          ) : (
                            <Pagination
                              activePage={energySearchPage}
                              totalPages={searchCount7}
                              onPageChange={handleSearchEnergyChange}
                            />
                          )}
                        </Table.Footer>
                      </>
                    )}
                  </Table>
                </Grid.Row>
              </Grid>
            </Segment>
            <Segment basic className="container-4">
              <Grid>
                <Grid.Row className="padding-zero">
                  {energyDetail && (
                    <Grid.Column className="content-4-header">
                      <Button
                        className="btn-green-2"
                        style={{
                          width: "350px",
                          height: "60px",
                          fontSize: "23px",
                        }}
                      >
                        {
                          selectedEnergyName?.owner //사용자 이름
                        }
                      </Button>
                    </Grid.Column>
                  )}
                </Grid.Row>
                {energyDetail && (
                  <Grid.Row className="content-4-container">
                    {energyDetail && index === 1 && (
                      <>
                        <Placeholder className="placeholder-image" />
                        <Grid.Row
                          columns={2}
                          className="content-4-sub-content-container"
                        >
                          <Grid.Column
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <div className="font-size-18">PV전압(V1)</div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.pvVoltage) &&
                                energyInfoData.pvVoltage !== null &&
                                energyInfoData.pvVoltage !== undefined &&
                                energyInfoData.pvVoltage !== ""
                                  ? energyInfoData.pvVoltage
                                  : 0}
                              </span>
                              <span className="unit"> v</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              출력전압(V2)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.outRVoltage) &&
                                energyInfoData.outRVoltage !== null &&
                                energyInfoData.outRVoltage !== undefined &&
                                energyInfoData.outRVoltage !== ""
                                  ? energyInfoData.outRVoltage
                                  : 0}
                              </span>
                              <span className="unit"> v</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              현재출력(P)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.outPower) &&
                                energyInfoData.outPower !== null &&
                                energyInfoData.outPower !== undefined &&
                                energyInfoData.outPower !== ""
                                  ? energyInfoData.outPower
                                  : 0}
                              </span>
                              <span className="unit"> W</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              주파수
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.frequency) &&
                                energyInfoData.frequency !== null &&
                                energyInfoData.frequency !== undefined &&
                                energyInfoData.frequency !== ""
                                  ? energyInfoData.frequency
                                  : 0}
                              </span>
                              <span className="unit"> Hz</span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            style={{
                              display: "inline-block",
                              marginLeft: 34,
                            }}
                          >
                            <div className="font-size-18">PV전류(I1)</div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.pvCurrent) &&
                                energyInfoData.pvCurrent !== null &&
                                energyInfoData.pvCurrent !== undefined &&
                                energyInfoData.pvCurrent !== ""
                                  ? energyInfoData.pvCurrent
                                  : 0}
                              </span>
                              <span className="unit"> A</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              출력전류(I2)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.outRCurrent) &&
                                energyInfoData.outRCurrent !== null &&
                                energyInfoData.outRCurrent !== undefined &&
                                energyInfoData.outRCurrent !== ""
                                  ? energyInfoData.outRCurrent
                                  : 0}
                              </span>
                              <span className="unit"> A</span>
                            </div>
                            <div className="font-size-18-with-margin">역률</div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.powerFactor) &&
                                energyInfoData.powerFactor !== null &&
                                energyInfoData.powerFactor !== undefined &&
                                energyInfoData.powerFactor !== ""
                                  ? energyInfoData.powerFactor
                                  : 0}
                              </span>
                              <span className="unit"> %</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              누적 발전량
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.accumulated / 1000) &&
                                (energyInfoData.accumulated / 1000).toFixed(
                                  1,
                                ) !== null &&
                                (energyInfoData.accumulated / 1000).toFixed(
                                  1,
                                ) !== undefined &&
                                (energyInfoData.accumulated / 1000).toFixed(
                                  1,
                                ) !== ""
                                  ? (energyInfoData.accumulated / 1000).toFixed(
                                      1,
                                    )
                                  : 0}
                              </span>
                              <span className="unit"> kWh</span>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                    {energyDetail && index === 2 && (
                      <>
                        <Placeholder2 className="placeholder-image" />
                        <Grid.Row
                          columns={1}
                          className="content-4-sub-content-container-2"
                        >
                          <Grid.Column
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <div className="font-size-18">급수배관온도(T1)</div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.inputTemperature) &&
                                energyInfoData.inputTemperature !== null &&
                                energyInfoData.inputTemperature !== undefined &&
                                energyInfoData.inputTemperature !== ""
                                  ? energyInfoData.inputTemperature
                                  : 0}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              급탕배관온도(T2)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.outputTemperature) &&
                                energyInfoData.outputTemperature !== null &&
                                energyInfoData.outputTemperature !==
                                  undefined &&
                                energyInfoData.outputTemperature !== ""
                                  ? energyInfoData.outputTemperature
                                  : 0}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">유량</div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.fluxUsed) &&
                                energyInfoData.fluxUsed !== null &&
                                energyInfoData.fluxUsed !== undefined &&
                                energyInfoData.fluxUsed !== ""
                                  ? energyInfoData.fluxUsed
                                  : 0}
                              </span>
                              <span className="unit"> LPM</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              누적 사용량
                            </div>
                            <div className="textfield">
                              <span className="number">
                                <span className="number">
                                  {!isNaN(energyInfoData.accumulatedUsed) &&
                                  energyInfoData.accumulatedUsed !== null &&
                                  energyInfoData.accumulatedUsed !==
                                    undefined &&
                                  energyInfoData.accumulatedUsed !== ""
                                    ? (
                                        energyInfoData.accumulatedUsed / 1000
                                      ).toFixed(1)
                                    : 0}
                                </span>
                              </span>
                              <span className="unit"> Kcal</span>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                    {energyDetail && index === 3 && (
                      <>
                        <Placeholder3 className="placeholder-image" />
                        <Grid.Row
                          columns={2}
                          className="content-4-sub-content-container"
                        >
                          <Grid.Column
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <div className="font-size-18">
                              집열기 입구온도(T1)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(
                                  energyInfoData.collectorInputTemperature,
                                ) &&
                                energyInfoData.collectorInputTemperature !==
                                  null &&
                                energyInfoData.collectorInputTemperature !==
                                  undefined &&
                                energyInfoData.collectorInputTemperature !== ""
                                  ? energyInfoData.collectorInputTemperature
                                  : 0}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              축열조 상부온도(T3)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(
                                  energyInfoData.heatTankTopTemperature,
                                ) &&
                                energyInfoData.heatTankTopTemperature !==
                                  null &&
                                energyInfoData.heatTankTopTemperature !==
                                  undefined &&
                                energyInfoData.heatTankTopTemperature !== ""
                                  ? energyInfoData.heatTankTopTemperature
                                  : 0}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              급수온도(T5)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.inputTemperature) &&
                                energyInfoData.inputTemperature !== null &&
                                energyInfoData.inputTemperature !== undefined &&
                                energyInfoData.inputTemperature !== ""
                                  ? energyInfoData.inputTemperature
                                  : 0}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              누적 사용량
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.accumulatedUsed) &&
                                energyInfoData.accumulatedUsed !== null &&
                                energyInfoData.accumulatedUsed !== undefined &&
                                energyInfoData.accumulatedUsed !== ""
                                  ? (
                                      energyInfoData.accumulatedUsed / 1000
                                    ).toFixed(1)
                                  : 0}
                              </span>
                              <span className="unit"> Kwh</span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            style={{
                              display: "inline-block",
                              verticalAlign: "top",
                              marginLeft: 34,
                            }}
                          >
                            <div className="font-size-18">
                              집열기 출구온도(T2)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(
                                  energyInfoData.collectorOutputTemperature,
                                ) &&
                                energyInfoData.collectorOutputTemperature !==
                                  null &&
                                energyInfoData.collectorOutputTemperature !==
                                  undefined &&
                                energyInfoData.collectorOutputTemperature !== ""
                                  ? energyInfoData.collectorOutputTemperature
                                  : 0}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              축열조 하부온도(T4)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(
                                  energyInfoData.heatTankBottomTemperature,
                                ) &&
                                energyInfoData.heatTankBottomTemperature !==
                                  null &&
                                energyInfoData.heatTankBottomTemperature !==
                                  undefined &&
                                energyInfoData.heatTankBottomTemperature !== ""
                                  ? energyInfoData.heatTankBottomTemperature
                                  : 0}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              급탕온도(T6)
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(energyInfoData.outputTemperature) &&
                                energyInfoData.outputTemperature !== null &&
                                energyInfoData.outputTemperature !==
                                  undefined &&
                                energyInfoData.outputTemperature !== ""
                                  ? energyInfoData.outputTemperature
                                  : 0}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              누적 생산량
                            </div>
                            <div className="textfield">
                              <span className="number">
                                {!isNaN(parseInt(energyInfoData.accumulated)) &&
                                parseInt(energyInfoData.accumulated) !== null &&
                                parseInt(energyInfoData.accumulated) !==
                                  undefined &&
                                parseInt(energyInfoData.accumulated) !== ""
                                  ? (
                                      parseInt(energyInfoData.accumulated) /
                                      1000
                                    ).toFixed(1)
                                  : 0}
                              </span>
                              <span className="unit"> Kcal</span>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                    {energyDetail && index === 4 && (
                      <>
                        <Placeholder4 className="placeholder-image" />
                        <Grid.Row
                          columns={2}
                          className="content-4-sub-content-container"
                        >
                          <Grid.Column
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <div className="font-size-18">누적발전량(W)</div>
                            <div className="textfield">
                              <span className="number">
                                {selectEnergyInfo.cumConsumePower}
                              </span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              에너지원
                            </div>
                            <div className="textfield">
                              <span className="number">84</span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              설비업체
                            </div>
                            <div className="textfield">
                              <span className="number">39</span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              모니터링업체
                            </div>
                            <div className="textfield">
                              <span className="number">0</span>
                              <span className="unit"> kWh</span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            style={{
                              display: "inline-block",
                              verticalAlign: "top",
                              marginLeft: 34,
                            }}
                          >
                            <div className="font-size-18">
                              집열기 출구온도(T2)
                            </div>
                            <div className="textfield">
                              <span className="number">83</span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              축열조 하부온도(T4)
                            </div>
                            <div className="textfield">
                              <span className="number">75</span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              급탕온도(T6)
                            </div>
                            <div className="textfield">
                              <span className="number">79</span>
                              <span className="unit"> ℃</span>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                    {energyDetail && index === 5 && (
                      <>
                        <Placeholder5 className="placeholder-image" />
                        <Grid.Row
                          columns={2}
                          className="content-4-sub-content-container"
                        >
                          <Grid.Column
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <div className="font-size-18">(전)전압</div>
                            <div className="textfield">
                              <span className="number">
                                {selectEnergyInfo.cumPower}
                              </span>
                              <span className="unit"> V</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              (전)전류
                            </div>
                            <div className="textfield">
                              <span className="number">-</span>
                              <span className="unit"> A</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              (전)출력
                            </div>
                            <div className="textfield">
                              <span className="number">-</span>
                              <span className="unit"> W</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              주파수
                            </div>
                            <div className="textfield">
                              <span className="number">-</span>
                              <span className="unit"> Hz</span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            style={{
                              display: "inline-block",
                              verticalAlign: "top",
                              marginLeft: 34,
                            }}
                          >
                            <div className="font-size-18">(후)전압</div>
                            <div className="textfield">
                              <span className="number">-</span>
                              <span className="unit"> V</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              (후)전류
                            </div>
                            <div className="textfield">
                              <span className="number">-</span>
                              <span className="unit"> A</span>
                            </div>
                            <div className="font-size-18-with-margin">
                              (후)출력
                            </div>
                            <div className="textfield">
                              <span className="number">-</span>
                              <span className="unit"> W</span>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                    {energyDetail && index === 6 && (
                      <>
                        <Placeholder6 className="placeholder-image" />
                        <Grid.Row
                          columns={2}
                          className="content-4-sub-content-container-3"
                        >
                          <Grid.Column
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <div className="font-size-18">(전)전압</div>
                            <div className="textfield">
                              <span className="number">
                                {selectEnergyInfo.todayPower}
                              </span>
                              <span className="unit"> V</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              (전)전류
                            </div>
                            <div className="textfield">
                              <span className="number">0</span>
                              <span className="unit"> A</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              (전)출력
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                              <span className="unit"> W</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              누적 사용열량
                            </div>
                            <div className="textfield">
                              <span className="number">1</span>
                              <span className="unit"> kWh</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              출수온도
                            </div>
                            <div className="textfield">
                              <span className="number">27.4</span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              주파수
                            </div>
                            <div className="textfield">
                              <span className="number">60</span>
                              <span className="unit"> Hz</span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            style={{
                              display: "inline-block",
                              marginLeft: 34,
                              verticalAlign: "top",
                            }}
                          >
                            <div className="font-size-18">(후)전압</div>
                            <div className="textfield">
                              <span className="number">0</span>
                              <span className="unit"> V</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              (후)전류
                            </div>
                            <div className="textfield">
                              <span className="number">0</span>
                              <span className="unit"> A</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              (후)출력
                            </div>
                            <div className="textfield">
                              <span className="number">0</span>
                              <span className="unit"> W</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              급수온도
                            </div>
                            <div className="textfield">
                              <span className="number">25.7</span>
                              <span className="unit"> ℃</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              역률
                            </div>
                            <div className="textfield">
                              <span className="number">99.8</span>
                              <span className="unit"> %</span>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                    {energyDetail && index === 7 && (
                      <>
                        <Placeholder7 className="placeholder-image" />
                        <Grid.Row
                          columns={2}
                          className="content-4-sub-content-container-3"
                        >
                          <Grid.Column
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <div className="font-size-18">(PCS입력)전압</div>
                            <div className="textfield">
                              <span className="number">
                                {selectEnergyInfo.soc}
                              </span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              (PCS입력)전류
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              (PCS입력)출력
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              SOC
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              최저온도
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              평균온도
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              배터리전류
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            style={{
                              display: "inline-block",
                              marginLeft: 34,
                              verticalAlign: "top",
                            }}
                          >
                            <div className="font-size-18">(배터리입력)전압</div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              (배터리입력)전류
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              (배터리입력)출력
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              SOH
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              최고온도
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                            <div className="font-size-18-with-margin-10">
                              배터리전압
                            </div>
                            <div className="textfield">
                              <span className="number">미전송</span>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                  </Grid.Row>
                )}
              </Grid>
            </Segment>
          </>
        )}
        {/* indexInfo 끝 */}
        {deleteCheck && (
          <DeletePopup
            setDeleteCheck={setDeleteCheck}
            selectNoteId={selectNoteId}
            selectIssueId={selectIssueId}
            getNotesWithIssueId={getNotesWithIssueId}
            setSelectNoteId={setSelectNoteId}
            setTempNoteList={setTempNoteList}
            setClickDiv={setClickDiv}
            fetchIssueCallback={fetchIssueCallback}
          />
        )}
        {writeNote && (
          <CompletePopup
            noteData={noteData}
            selectNoteId={selectNoteId}
            noteDataCallback={noteDataCallback}
            selectIssueId={selectIssueId}
            getNotesWithIssueId={getNotesWithIssueId}
            setWriteNote={setWriteNote}
            fileInputRef={fileInputRef}
            handlePreview={handlePreview}
            handleFileUpload={handleFileUpload}
            secondFileInputRef={secondFileInputRef}
            fetchIssueCallback={fetchIssueCallback}
          />
        )}
        {writeIssue && (
          <IssueRegister
            // fetchDataCallback={fetchDataCallback}
            selectIssueId={selectIssueId}
            handleBreakListCall={handleBreakListCall}
            issueData={issueData}
            token={token}
            setLoading={setLoading}
            setIssueData={setIssueData}
            setWriteIssue={setWriteIssue}
            selectEquipmentId={selectEquipmentId}
            fetchIssueCallback={fetchIssueCallback}
            getNotesWithIssueId={getNotesWithIssueId}
          />
        )}
        {/* 로그인 시작 */}
        {selectedIndex === 3 && inputId === "" ? (
          <LoginPage
            onLogin={handleLogin}
            sendSelectedIndex={handleSelectedIndex}
          />
        ) : (
          selectedIndex !== 0 &&
          selectedIndex !== 1 &&
          selectedIndex !== 2 &&
          selectedIndex === 3
        )}
      </SideBar.Pusher>
    </SideBar.Pushable>
  );
};

export default Main;
